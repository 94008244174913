@import '../Assets/sass/base/fonts';
@import '../Assets/sass/base/global';
@import '../Assets/sass/abstracts/colors';
@import '../Assets/sass/abstracts/mixins';

.comment-textarea {
  resize: none;
  width: 100%;
  color: $bg-gray-mist;
  padding: 10px;
  margin-top: 10px;
  border: 1px solid $bdr-clr-c1c1;
  font-family: $Commissioner-Regular;
  @extend .Commissioner-Regular14;
  border-radius: 3px;
  box-sizing: border-box;

  &.Mui-disabled {
    opacity: 0.4;
  }

  &:focus {
    border: 1px solid $text-clr-BC;
    outline: none;
  }

  &.fieldError {
    border: 1px solid $text-clrC1;
  }
}

.btn-wrapper-comment {
  display: flex;
  margin-top: 30px;

  @include respond-to('small') {
    margin-top: 15px;
  }

  &.step-2 {
    flex-direction: column;
  }

  .all-error {
    color: $text-22-clr;
    font-family: $Commissioner-Regular;
    @extend .Commissioner-Medium14;
    margin: 0 0;
    position: relative;
    top: -30px;
  }

  button {
    padding: 13px 20px;
    min-height: unset;
    line-height: 1;
    color: $text-clr-BC;
    font-family: $Commissioner-Medium;
    border: 1px solid $text-clr-BC;
    border-radius: 4px;
    text-transform: capitalize;
    @extend .Commissioner-Medium15;
    white-space: nowrap;
    box-shadow: none;

    &:hover {
      box-shadow: none;
    }

    .MuiButton-startIcon {
      margin-right: 10px;

      .MuiSvgIcon-root {
        font-size: 18px;
      }
    }

    @include respond-to('small') {
      padding: 9px 16px;

      .MuiButton-startIcon {
        margin-right: 4px;

        .MuiSvgIcon-root {
          font-size: 14px;
        }
      }
    }
  }

  .MuiButton-outlinedPrimary,
  .MuiButton-outlinedPrimary:hover {
    color: $text-clr-BC;
    background-color: $text-clr-white;
  }

  .MuiButton-containedPrimary,
  .MuiButton-containedPrimary:hover {
    margin-right: 20px;
    background-color: $text-clr-BC;
    color: $text-clr-white;
  }

  .MuiButton-contained.Mui-disabled {
    border-color: transparent;
  }
}

.inbox_page_wrapper {
  background-color: $bg-White-SmokeF7;

  .inbox_page_wrapper_section {
    @extend .max_width;
    padding: 20px 0px;
    width: 94%;
    max-width: 1366px;

    .inbox_left_section {
      &.col-3 {
        display: block;
        flex: 0 0 21%;
        margin-left: 0;
        margin-right: 20px;
        width: 21%;

        @include respond-to('medium') {
          width: 100%;
        }
      }

      .inbox_menu_wrapper {
        background-color: $bg-white;
        border-radius: 4px;

        .inbox_menu {
          padding: 26px 25px 30px;

          .subheader {
            font-family: $Commissioner-Regular;
            @extend .Commissioner-Regular15;
            padding: 0 15px;
            color: $text-clr-99;
            margin-bottom: 10px;

            &.top-border {
              border-top: 1px solid $bg-Platinum-gray;
              padding-top: 20px;
              margin-top: 10px;
            }

            @include respond-to('small') {
              padding: 0;
            }
          }

          .MuiListItem-root {
            padding: 8px 15px 10px;
            border-radius: 3px;
            color: $text-clr-mistblue-77;

            a {
              display: block;
              color: inherit;
              text-decoration: none;
              padding-left: 18px;

              @include respond-to('small') {
                padding-left: 0;
              }
            }

            &.active-menu {
              background-color: $bg-White-SmokeF7;

              a {
                color: $text-clr-33;
              }
            }

            .MuiListItemText-root {
              margin: 0;

              .MuiListItemText-primary {
                font-family: $Commissioner-Medium;
                @extend .Commissioner-Medium15;
              }
            }

            &.active {
              background-color: $bg-White-SmokeF7;

              .MuiListItemText-primary {
                color: $text-clr-33;
                font-weight: 600;
              }
            }

            .MuiListItemIcon-root {
              min-width: auto;
              color: $text-clr-BC;
            }
          }

          .indent {
            a {
              padding-left: 30px;

              @include respond-to('small') {
                padding-left: 15px;
              }
            }
          }

          @include respond-to('small') {
            padding: 16px 15px 20px;
          }

          .inbox_menucounter {
            background-color: $text-clr-BC;
            border-radius: 3px;
            padding: 2px 6px;
            min-width: 10px;
            text-align: center;
            display: flex;
            align-items: center;
            justify-content: center;

            .menucounter_text {
              @extend .Commissioner-SemiBold;
              font-family: $Commissioner-SemiBold;
              color: $text-clr-white;
              font-size: 13px;
            }

            @include respond-to('large') {
              min-width: unset;
              padding: 0 6px;

              .menucounter_text {
                font-size: 11px;
                letter-spacing: 0.038rem;
              }
            }

            @include respond-to('small') {
              padding: 0 4px;

              .menucounter_text {
                font-size: 9px;
              }
            }
          }
        }
      }
    }

    .inbox_right_section {
      width: 78%;
      float: right;
      margin-bottom: 10px;

      @include respond-to('medium') {
        width: 100%;
        clear: both;
      }

      .heading_section_wrapper {
        padding: 30px;
        background-color: $text-clr-white;
        border-radius: 4px;

        .MuiGrid-root {
          justify-content: space-between;
          align-items: center;

          @include respond-to('small') {
            flex-wrap: wrap;
          }

          .section_heading {
            font-family: $Karbon-Regular;
            @extend .Karbon-Regular26;
            color: $text-clrE20;
            padding: 15px 0;
          }

          .search_filter_wrapper {
            display: flex;

            @include respond-to('exx-small') {
              margin-top: 10px;
            }

            .search_wrapper {
              display: flex;
              align-self: center;
              align-items: center;
              border: 1px solid $bg-gray-Mountain-Mist;
              border-radius: 3px;
              width: 400px;
              padding: 12px 14px;

              @include respond-to('small') {
                width: 200px;
                padding: 8px 10px;
              }

              .search_box {
                width: 100%;
                border-color: transparent;
                border-radius: 3px;
                outline: none;

                &::placeholder {
                  font-family: $Commissioner-Regular;
                  @extend .Commissioner-Regular17;
                  color: $text-clr-boulder-77;
                }
              }

              .MuiSvgIcon-root {
                color: $bg-gray-mist;
                font-size: 25px;
                margin-right: 15px;
              }
            }

            .MuiIconButton-root {
              border: 1px solid $bg-gray-Mountain-Mist;
              border-radius: 4px;
              color: $bg-gray-Mountain-Mist;
              margin-left: 10px;
              padding: 0px 13px;

              &:hover {
                background-color: transparent;
              }
            }
          }
        }

        .section_sub {
          p {
            margin: 0;
            font-family: $Commissioner-Regular;
            @extend .Commissioner-Medium14;
            color: $text-clr-mistblue-77;

            a {
              color: inherit;
              margin-bottom: 5px;
              text-decoration: none;
              display: flex;

              svg {
                font-size: 23px;
                margin-left: -6px;
              }
            }
          }
        }
      }

      .inbox_details_wrapper {
        .inbox_details_item {
          padding: 30px;
          margin-top: 10px;
          background-color: $text-clr-white;
          border-radius: 4px;

          .MuiGrid-container {
            .MuiGrid-item {
              margin-bottom: 30px;

              @include respond-to('small') {
                margin-bottom: 22px;
              }

              .details-text-label {
                font-family: $Commissioner-Regular;
                @extend .Commissioner-Regular15;
                color: $text-clr-mistblue-77;
                margin-bottom: 8px;

                @include respond-to('small') {
                  margin-bottom: 4px;
                }
              }

              .details-text {
                font-family: $Commissioner-Medium;
                @extend .Commissioner-Medium15;
                color: $text-clr-33;

                a {
                  color: $text-clr-BC;
                }
              }
            }
          }

          .mycomments_status {
            .mycomment-wrapper {
              .comment-label {
                @extend .Commissioner-Medium15;
                font-family: $Commissioner-Medium;
                color: $text-clr-33;
              }

              .error-desc {
                color: $text-22-clr;
              }
            }

            .comment_error_msg {
              margin-top: 10px;
              color: $text-22-clr;
            }

            .MuiAlert-standard {
              padding: 8px 20px;
              align-items: flex-start;

              @include respond-to('small') {
                padding: 8px 12px;
              }

              .MuiAlert-icon {
                padding: 0;
                margin-right: 10px;
                margin-top: 6px;

                @include respond-to('small') {
                  margin-right: 5px;
                  margin-top: 3px;

                  .MuiSvgIcon-root {
                    font-size: 14px;
                  }
                }
              }

              .MuiAlert-message {
                padding: 0;
                max-width: 85%;
                margin-top: 3px;

                .alert_comment {
                  font-family: $Commissioner-Regular;
                  @extend .Commissioner-Regular15;
                  color: $text-clr91;
                  margin-top: 10px;
                }
              }

              .MuiAlertTitle-root {
                margin: 0;
                font-family: $Commissioner-Medium;
                @extend .Commissioner-Medium15;
                color: $text-clr91;
                font-weight: bold;
              }

              .MuiAlert-action {
                padding: 0;

                .MuiButton-text {
                  text-transform: none;
                  margin-left: 0;
                  min-width: 74px;

                  span {
                    font-family: $Commissioner-Regular;
                    @extend .Commissioner-Regular15;
                    color: $text-clr91;
                    font-weight: 600;
                  }
                }
              }
            }

            .MuiAlert-standardSuccess {
              background-color: $bg-success;

              .MuiAlert-icon {
                color: $text-B0F-clr;
              }
            }

            .MuiAlert-standardError {
              background-color: $bg-error;

              .MuiAlert-icon {
                color: $text-22-clr;
              }
            }

            .MuiAlert-standardInfo {
              background-color: $bg-White-SmokeF7;

              .MuiAlert-icon {
                color: $bg-gray-mist;
              }

              .MuiAlert-message {
                margin-top: 1px;
              }
            }
          }
        }

        .inbox_pagination {
          .MuiTablePagination-selectLabel {
            @extend .Commissioner-Regular14;
            font-family: $Commissioner-Regular;
            color: $text-clr-33;
          }
        }
      }
    }
  }
}
