div.auditItem-card{
  max-width: 300px;
  margin: auto;
}

div.recipient-select-wrapper{
  margin-top: 15px;
  text-align: center;
}

div.formControl-wrapper{
  width: 100%;
  text-align: center;
  display: block;
}

div.recipient-select{
  width: 75%;
  min-width: 120px;
}

div.button-group{
  margin: auto;
  text-align: center;
}

button.actionButton{
  margin: 15px;
  min-width: 90px;
}

div.outline-box{
  padding-top: 10px;
  padding-bottom: 10px;
}

@media only screen and (max-width: 600px) {
  div.outline-box{
    margin-bottom: 10rem;
  }
}
