@import '../abstracts/mixins';
$columns: 12;

@for $i from 1 through $columns {
  .col-#{$i} {
    flex: 0 0 ((calc(100%/12))*$i)-3.3;
    width: ((calc(100%/12))*$i)-3.3;
    margin-right: 15px;
    margin-left: 15px;
    margin-bottom: 15px;
    box-sizing: border-box;
    display: flex;
    align-items: stretch;
    position: relative;
  }
}

.col-4 {
  &:nth-child(3n) {
    margin-right: 0%;
  }

  @include respond-to('medium') {
    width: 48%;
    flex: 0 0 48%;
    margin: 0 1% 20px 1%;

    .col-4:nth-child(2n) {
      margin-right: 0px;
    }

    .col-4:nth-child(3n) {
      margin-right: 2%;
    }
  }

  @include respond-to('small') {
    width: 100%;
    flex: 0 0 100%;

    .col-4:nth-child(2n) {
      margin-right: 0px;
    }

    .col-4:nth-child(3n) {
      margin-right: 0px;
    }
  }
}
