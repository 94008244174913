@import '../base/fonts';
@import '../base/global';
@import '../abstracts/colors';
@import '../abstracts/mixins';
@import '../base/grid';

.inbox_menu {
  .MuiMenu-list {
    padding-bottom: 0;

    .MuiTabs-root {
      border-bottom: 1px solid $bg-clr-E7;

      .box-title {
        padding-right: 64px;
        padding-left: 23px;

        @include respond-to('small') {
          padding-right: 25px;
          padding-left: 20px;
        }
      }

      .MuiTab-root {
        text-transform: none;
        font-family: $Commissioner-Medium;
        @extend .Commissioner-Medium16;
        color: $text-clr-mistblue-77;
        padding-top: 12px;

        @include respond-to('small') {
          flex: 1 1 auto;
        }

        &.Mui-selected {
          color: $text-clr-BC !important;
        }
      }

      .MuiTabs-indicator {
        height: 3px;
        border-radius: 10px;
      }
    }

    .notification_item_wrapper,
    .announcements_item_wrapper {
      overflow-y: auto;
      padding: 10px 18px 0 26px;
      max-height: 65vh;

      @include respond-to('small') {
        padding: 10px 14px 0;
      }

      @media (min-height: 665px) {
        max-height: 430px;
      }

      @media (max-height: 414px) {
        max-height: 200px;
      }

      .notification_items,
      .announcements_items {
        padding: 20px 0;
        border-bottom: 1px solid $bg-Platinum-gray;
        white-space: normal;

        &:last-child {
          border-bottom: none;
        }

        &:hover {
          background-color: transparent;
        }

        a {
          align-items: flex-start;
          display: flex;
          text-decoration: none;
          color: inherit;
          width: 100%;

          .request_icon {
            height: 34px;
            margin-right: 18px;
            display: flex;
            align-items: center;
            min-width: 34px;
            border-radius: 50%;

            &.check {
              background-color: $bg-success;
            }

            &.people {
              background-color: #e2f4ff;

              svg {
                path {
                  fill: $bg-French-Blue;
                }
              }
            }

            &.close {
              background-color: $bg-error;

              svg {
                path {
                  fill: $text-22-clr;
                }

                height: 11px;
              }
            }

            svg {
              display: block;
              width: 100%;
            }
          }

          .person_icon {
            width: 34px;
            height: 34px;
            margin-right: 18px;
          }

          .notification_message_wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;

            .title {
              font-family: $Commissioner-SemiBold;
              @extend .Commissioner-SemiBold;
              color: $text-clr-33;
              flex-grow: 1;
            }

            .date {
              font-family: $Commissioner-Medium;
              @extend .Commissioner-Medium15;
              color: $text-clr-99;
            }

            .message {
              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-line-clamp: 2;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              width: 100%;
              flex: 1 1 100%;
              font-family: $Commissioner-Regular;
              @extend .Commissioner-Regular15;
              color: $text-clr-mistblue-77;
              margin-top: 6px;

              >*:first-child {
                font-family: $Commissioner-Regular;
                @extend .Commissioner-Regular15;
                color: $text-clr-mistblue-77;
                margin-top: 0;
                margin-bottom: 0;
                font-weight: normal;
              }

              * {
                font-family: $Commissioner-Regular;
                @extend .Commissioner-Regular15;
                color: $text-clr-mistblue-77;
                margin-top: 0;
                margin-bottom: 0;
                font-weight: normal;
              }
            }
          }

          .announcements_message_wrapper {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;

            .date {
              font-family: $Commissioner-Medium;
              @extend .Commissioner-Medium15;
              color: $text-clr-99;
            }

            .name_info_wrapper {
              display: flex;
              flex-direction: row;
              width: 100%;

              .name_info {
                display: flex;
                justify-content: space-between;
                width: 100%;
                flex-wrap: wrap;

                .name {
                  font-family: $Commissioner-Medium;
                  @extend .Commissioner-Medium15;
                  color: $text-clr-33;
                  flex-grow: 1;

                  .separator {
                    padding: 0 5px;
                    color: $text-clr70;
                  }

                  .email {
                    font-family: $Commissioner-Regular;
                    @extend .Commissioner-Regular15;
                    color: $text-clr70;
                  }
                }

                .status {
                  font-family: $Commissioner-SemiBold;
                  @extend .Commissioner-SemiBold;
                  color: $text-clr-BC;
                  margin-top: 8px;
                  flex: 1 1 100%;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  -webkit-line-clamp: 1;
                  display: -webkit-box;
                  -webkit-box-orient: vertical;
                }
              }
            }

            .announcement_message {
              overflow: hidden;
              text-overflow: ellipsis;
              -webkit-line-clamp: 2;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              width: 100%;
              flex: 1 1 100%;
              margin-top: 8px;
              margin-bottom: 0;
              font-family: $Commissioner-Regular;
              @extend .Commissioner-Regular15;
              color: $text-clr-33;
              font-weight: normal;
            }

            .tag_wrapper {
              margin-top: 10px;

              .tag {
                display: inline-flex;
                font-family: $Commissioner-Medium;
                @extend .Commissioner-Medium13;
                color: $text-clr-white;
                padding: 2px 6px;
                border-radius: 3px;
                font-size: 10px;
                margin-right: 10px;

                &.global {
                  background-color: black;
                }

                @include respond-to('small') {
                  font-size: 8px;
                }
              }
            }
          }
        }
      }
    }

    .viewall_wrapper {
      border-top: 1px solid $bg-clr-E7;
      color: $text-clr-BC;
      font-family: $Commissioner-Regular;
      @extend .Commissioner-Regular15;

      >div {
        a {
          color: inherit;
          text-decoration: none;
        }
      }
    }

    .empty_list {
      min-height: 65vh;
      width: 600px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-family: $Commissioner-Medium;
      @extend .Commissioner-Medium16;
      color: $text-clr-mistblue-77;

      @media (min-height: 665px) {
        min-height: 430px;
      }

      @media (max-height: 414px) {
        min-height: 200px;
      }

      @media (max-width: 600px) {
        width: calc(100vw - 32px);
      }

    }

    .list_loader {
      min-height: 65vh;
      width: 600px;

      @media (min-height: 665px) {
        min-height: 430px;
      }

      @media (max-height: 414px) {
        min-height: 200px;
      }

      @media (max-width: 600px) {
        width: calc(100vw - 32px);
      }

      .inbox-loading {
        min-height: 65vh;
        width: 600px;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (min-height: 665px) {
          min-height: 430px;
        }

        @media (max-height: 414px) {
          min-height: 200px;
        }

        @media (max-width: 600px) {
          width: calc(100vw - 32px);
        }
      }
    }
  }
}
