@import '../../../Assets/sass/base/fonts';
@import '../../../Assets/sass/base/global';
@import '../../../Assets/sass/abstracts/colors';
@import '../../../Assets/sass/abstracts/mixins';

.modal-wrapper {
  padding: 0;

  .close-modal {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;

    .MuiSvgIcon-root {
      color: $text-clr70;
      font-size: 19px;
    }
  }

  .edit-member-modal-content {
    clear: both;
    padding: 30px;

    .heading {
      font-family: $Commissioner-Medium;
      color: $text-clr-33;
      @extend .Commissioner-Medium21;
      margin-top: 0px;
      font-weight: normal;
      margin-bottom: 20px;

      @include respond-to('small') {
        margin-bottom: 10px;
      }
    }

    .sub-heading {
      font-family: $Commissioner-Regular;
      @extend .Commissioner-Medium14;
      color: $text-clr-33;
      padding-bottom: 10px;

      @include respond-to('small') {
        padding-bottom: 10px;
      }
    }

    .edit-member-form-wrapper {
      .edit-member-details-wrapper {
        padding: 10px 0px;

        .member-info {
          display: flex;
          flex-wrap: wrap;

          .member-item {
            width: 50%;
          }
        }

        .error-desc {
          padding-top: 6px;
          display: block;
          color: $text-clrC1;
          font-family: $Commissioner-Regular;
        }
      }

      .edit-member-role-wrapper {
        padding: 10px 0px;

        select {
          background-color: $text-clr-white;
          border: 1px solid $bdr-clr-c1c1;
          width: 100%;
          padding: 10px;
          margin-top: 10px;
          border-radius: 4px;
          box-sizing: border-box;
          font-family: $Commissioner-Regular;
          @extend .Commissioner-Medium14;
          color: $text-clr-33;

          &:focus {
            outline: none;
            border: 1px solid $text-clr-BC;
          }
        }

        .error-desc {
          padding-top: 6px;
          display: block;
          color: $text-clrC1;
          font-family: $Commissioner-Regular;
        }

        &.fieldError {
          select {
            border: 1px solid $text-clrC1;
          }
        }
      }

      .description-wrapper {
        padding: 10px 0px;

        .description-textarea {
          resize: none;
          width: 100%;
          color: $bg-gray-mist;
          padding: 10px;
          margin-top: 10px;
          border: 1px solid $bdr-clr-c1c1;
          font-family: $Commissioner-Regular;
          @extend .Commissioner-Regular14;
          border-radius: 3px;
          box-sizing: border-box;

          &:focus {
            border: 1px solid $text-clr-BC;
            outline: none;
          }

          &.fieldError {
            border: 1px solid $text-clrC1;
          }
        }

        .error-desc {
          padding-top: 6px;
          display: block;
          color: $text-clrC1;
          font-family: $Commissioner-Regular;
        }
      }
    }
  }

  .fieldHeading {
    @extend .Commissioner-Regular15;
    padding-bottom: 10px;
    font-family: $Commissioner-Medium;
  }

  .fieldError {
    z-index: 999;

    &:focus {
      z-index: 999;
    }
  }

  .fieldHasError {
    legend.fieldHeading {
      color: $text-clrC1;
    }
  }

  // Button css
  .btn-wrapper-edit {
    display: flex;
    margin-top: 30px;
    justify-content: flex-end;

    @include respond-to('small') {
      margin-top: 15px;
    }

    button {
      padding: 13px 20px;
      min-height: unset;
      line-height: 1;
      color: $text-clr-BC;
      font-family: $Karbon-Medium;
      border: 1px solid $text-clr-BC;
      border-radius: 4px;
      text-transform: capitalize;
      @extend .Karbon-Medium18;
      white-space: nowrap;
      box-shadow: none;

      &:hover {
        box-shadow: none;
      }
    }

    .MuiButton-outlinedPrimary,
    .MuiButton-outlinedPrimary:hover {
      margin-right: 20px;
      color: $text-clr-BC;
      background-color: $text-clr-white;
    }

    .MuiButton-containedPrimary,
    .MuiButton-containedPrimary:hover {
      background-color: $text-clr-BC;
      color: $text-clr-white;
    }

    .MuiButton-outlined.Mui-disabled {
      color: rgba(0, 0, 0, 0.26);
      border: 1px solid rgba(0, 0, 0, 0.12);
    }

    .MuiButton-contained.Mui-disabled {
      border-color: transparent;
      color: rgba(0, 0, 0, 0.26);
      box-shadow: none;
      background-color: rgba(0, 0, 0, 0.12);
    }
  }
}
