.content-wrapper.loader {
  display: flex;
  min-height: calc(100vh - 216px);
  width: 100%;
  padding: 32px 0;
}

.content-wrapper.loader>span {
  display: flex;
  margin: 0 auto;
}
