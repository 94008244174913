.error-message {
  margin-bottom: 1em;
  font-size: 24px;
  display: flex;
  flex-direction: row;
}

.suggestion-header {
  margin-bottom: 0.5em;
  font-size: 24px;
  margin-left: 2em;
}

.search-term {
  color: #3f51b5;
}

.suggestion {
  margin-bottom: 0.5em;
  font-size: 24px;
  margin-left: 3em;
}
