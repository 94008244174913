@import '../Assets/sass/base/fonts';
@import '../Assets/sass/base/global';
@import '../Assets/sass/abstracts/colors';
@import '../Assets/sass/abstracts/mixins';

.fav_page_wrapper {
  width: 100%;

  .group_page_wrapper {
    background-color: $bg-White-SmokeF7;
    padding-bottom: 50px;

    .group_wrapper_section {
      @extend .max_width;
      padding: 0px;
      width: 94%;
      max-width: 1366px;

      .page-group-sec {

        .grp_inner_wpr {
          background-color: $text-clr-white;
          border-radius: 4px;
          border: 1px solid $bg-clr-DE;
          width: 100%;
          box-sizing: border-box;
          display: flex;
          flex-direction: column;

          .group_innersection {
            border: 1px solid $bg-clr-DE;
            border-radius: 4px;
            background-color: $bg-white;
            padding: 20px 20px;
            width: 100%;
            box-sizing: border-box;

            .group_innerheading {
              font-family: $Karbon-Medium;
              @extend .Karbon-Medium18;
              padding-bottom: 0px;
              color: $text-clrE20;

              &.with_just_added {
                width: 65%;
              }

              .just-added {
                top: 25px;
                right: 20px;
              }
            }

            .group_subheading {
              color: $bg-clr-8A;
              @extend .Commissioner-Medium14;
              font-family: $Commissioner-Medium;
              padding: 8px 0 82px 0;
            }

            a {
              background-color: $bg-white;
              width: 100%;
              box-sizing: border-box;
              text-decoration: none;
              color: $text-clrE20;
            }
          }
        }
      }

      .data_count-wrapper {
        display: flex;
        color: $text-clr-boulder-77;
        font-family: $Karbon-Medium;
        @extend .Karbon-Medium17;
        justify-content: space-between;
        line-height: 0px;
        position: absolute;
        bottom: 20px;
        left: 20px;
        right: 10px;

        .data_count_innerleft {
          display: flex;
          align-items: center;
          color: $text-clr7B;
          font-family: $Commissioner-Medium;
          font-size: 0.875rem;

          .data_count {
            margin-right: 20px;
            display: flex;
            align-items: center;

            img {
              margin-right: 8px;
              width: 14px;
            }
          }

          .data_statics {
            display: flex;
            align-items: center;

            img {
              margin-right: 8px;
              width: 14px;
            }
          }
        }
      }
    }
  }

  .col-3 {
    margin-left: 0px;
    margin-right: 2.9%;
    flex: 0 0 22.8%;
    width: 22.8%;

    &:nth-child(4n) {
      margin-right: 0px;
    }

    @include respond-to('large') {
      flex: 0 0 23.5%;
      width: 23.5%;
      margin-right: 2%;
    }

    @include respond-to('medium') {
      flex: 0 0 32%;
      width: 32%;
      margin-right: 2%;

      &:nth-child(3n) {
        margin-right: 0px;
      }

      &:nth-child(4n) {
        margin-right: 2%;
      }
    }

    @include respond-to('small') {
      flex: 0 0 48%;
      width: 48%;
      margin-right: 4%;

      &:nth-child(2n) {
        margin-right: 0%;
      }

      &:nth-child(odd) {
        margin-right: 4%;
      }
    }

    @include respond-to('ex-small') {
      flex: 0 0 100%;
      width: 100%;
      margin-right: 0%;

      &:nth-child(4n),
      &:nth-child(3n),
      &:nth-child(2n) {
        margin-right: 0px;
      }
    }
  }

  .data_count-wrapper,
  .count {
    .count_right {
      .t_dots {
        font-size: 28px;
        color: $bg-clr-99;
        cursor: pointer;
      }

      .t_option {
        position: relative;
        display: block;
        right: -10px;

        .t_inner_options {
          list-style: none;
          position: absolute;
          width: 171px;
          margin: 0 0;
          padding: 15px 15px 5px 15px;
          border: 1px solid $bf-gray-Seashell;
          background-color: $bg-white;
          right: 0px;
          border-radius: 5px;
          z-index: 99;

          li,
          a {
            font-family: $Commissioner-Medium;
            @extend .Commissioner-Medium14;
            color: $text-clr-33;
            @extend .Commissioner-Medium14;
          }

          li {
            padding: 0 0 10px 0;
            cursor: pointer;
          }
        }
      }
    }
  }

  .fav_application_wrapper {
    display: none;
  }

  .fullscreen-enabled .fav_application_wrapper {
    display: block;
  }

  .fav-wrapper {
    padding: 20px 0px 22px 0px;
    width: 94%;
    max-width: 1366px;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    @include respond-to('ex-small') {
      align-items: baseline;
    }

    .fav_inner_left {
      color: $text-clr-33;
      font-family: $Commissioner-SemiBold;
      @extend .Commissioner-Regular14;
      font-size: 1.25rem;
    }

    .fav_inner_right {
      display: flex;
      align-items: center;
      color: $text-clr-boulder-77;
      font-family: $Commissioner-SemiBold;
      @extend .Commissioner-Medium14;
      justify-content: space-between;
      flex-wrap: wrap;

      @include respond-to('ex-small') {
        padding-top: 5px;
      }

      .sort_by_section {
        margin-right: 15px;
        position: relative;
        display: flex;
        align-items: center;

        .selected_value {
          color: $text-clr-boulder-77;
          font-family: $Commissioner-SemiBold;
          @extend .Commissioner-Medium14;
          border: none;
          background-color: transparent;
          margin-left: 8px;
          outline: none;
          display: flex;
          cursor: pointer;
          align-items: center;
        }

        .sorting_value {
          list-style: none;
          background-color: $bg-white;
          padding: 15px 20px 10px 20px;
          margin: 0 0;
          position: absolute;
          border: 1px solid $bf-gray-Seashell;
          border-radius: 4px;
          color: $text-clr-33;
          right: 0px;
          width: 100px;
          top: 25px;
          display: none;

          li,
          li a {
            padding-bottom: 10px;
            font-family: $Commissioner-Medium;
            @extend .Commissioner-Regular14;
            cursor: pointer;
          }
        }
      }

      .search_wrapper {
        display: flex;
        align-items: center;

        .MuiSvgIcon-root {
          cursor: pointer;
        }

        input {
          background-color: transparent;
          border: none;
          outline: none;
          border-bottom: 1px solid $bg-Platinum-gray;
          width: 265px;
          height: 32px;
        }
      }
    }
  }

  .group_wrapper_section {
    padding: 0px;

    .group_heading_wrapper {
      padding: 7px 0 0 0;
      display: flex;
      justify-content: space-between;
    }

    .just-added {
      font-family: $Commissioner-SemiBold;
      position: absolute;
      top: 15px;
      right: 15px;
      color: $text-clr-white;
      background-color: $text-clr-BC;
      border-radius: 9px;
      font-size: 12px;
      padding: 3px 8px 5px 8px;
      line-height: 14px;
      z-index: 1;

      @include respond-to('large') {
        font-size: 9.5px;
      }
    }

    .group_page_section {
      background-color: $bg-white;
      border: 1px solid $bg-clr-DE;
      border-radius: 4px;
      width: 94%;
      margin: auto;
      max-width: 1366px;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .application_card_section {
        width: 100%;
        border: 1px solid $bg-clr-DE;
        border-radius: 4px;

        a {
          text-decoration: none;
          color: $text-clrE20;
        }

        .image {
          min-height: 160px;
          width: 100%;
          background-color: $bg-clr-E1;
          position: relative;
          line-height: 0px;

          &:hover {
            .image-cover {
              display: block;
            }
          }

          .image-cover {
            width: 100%;
            height: 100%;
            background-color: #002352a3;
            position: absolute;
            top: 0px;
            display: none;

            .icon_expand {
              position: absolute;
              top: 50%;
              right: 50%;
              transform: translate(50%, -50%);
              color: $bg-white;
              font-family: $Commissioner-SemiBold;
              @extend .Commissioner-Medium14;
              display: flex;
              align-items: center;
              max-height: 15px;
              cursor: pointer;

              img {
                width: 15px;
                color: $bg-white;
                margin-right: 6px;
              }
            }
          }

          img {
            height: 160px;
            width: 100%;
          }
        }

        .app_section_bottom {
          padding: 18px 10px 15px 20px;

          .name {
            font-family: $Karbon-Medium;
            @extend .Karbon-Medium18;
            color: $text-clrE20;
            min-height: 55px;
            padding-bottom: 50px;
            cursor: pointer;
            padding-right: 10px;
          }

          .count {
            display: flex;
            justify-content: space-between;
            position: absolute;
            left: 20px;
            bottom: 15px;
            right: 10px;

            .count_left {
              color: $bg-gray-Boulder;
              font-family: $Commissioner-Medium;
              @extend .Commissioner-Regular14;
            }
          }
        }
      }
    }
  }

  .row {
    padding: 30px 30px 15px 30px;
    flex-wrap: wrap;
    width: 100%;

    .no_item_group {
      text-align: center;
      width: 100%;
      padding: 30px 0px 45px 0px;

      .no_fav {
        padding: 0px 0px 20px 0px;
        margin: 0 0;
        font-family: $Commissioner-Medium;
        @extend .Commissioner-Regular16;
        color: $bg-gray-mist;
      }

      a {
        color: $text-clr-BC;
        text-decoration: none;
        font-family: $Commissioner-Medium;
      }
    }
  }

  .group_search {
    cursor: pointer;
    color: $bg-gray-Boulder;

    @include respond-to('ex-small') {
      padding-top: 5px;
    }

    input {
      border: none;
      background: transparent;
      outline: none;

      &:focus {
        outline: none;
      }

      &::placeholder {
        font-family: $Commissioner-Regular;
        @extend .Commissioner-Regular14;
        color: $text-clr-boulder-77;
      }
    }

    .group_inner_search {
      display: flex;
      align-items: center;
      border: solid 1px $bg-clr-99;
      padding: 10px 15px;
      border-radius: 3px;

      @include respond-to('small') {
        padding: 5px;
      }

      .MuiSvgIcon-root {
        font-size: 18px;
        margin-right: 10px;
        color: $text-clr-mistblue-77;

        @include respond-to('small') {
          font-size: 14px;
          margin-right: 5px;
        }
      }
    }
  }

  .See_more {
    text-align: center;
    color: $text-clr-BC;
    font-family: $Karbon-Medium;
    @extend .Karbon-Medium18;
    cursor: pointer;
    width: 100%;
    padding-bottom: 30px;
    padding-top: 0px;
  }
}
