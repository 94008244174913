#navbar{
    padding: none;
    background-color: #FFFFFF;
    overflow: hidden;
    display: flex;
    position: sticky;
    align-items: center;
  }
  .navbarSection{
    text-align: center;
    padding: 10px 0px;
    text-decoration: none;
    font-size: 17px;
    overflow: hidden;
  }
  img.navElement{
    cursor: pointer;
  }
  button{
    text-align: center;
    padding: 0px 0px;
    text-decoration: none;
    font-size: 17px;
    overflow: hidden;
    background-color: #ffffff00;
    border: none;
    display: inline-block;
    margin: 0px 50px 0px 40px
  }
  img{
    width: 35px;
    height: 35px;
  }
  @media only screen and (max-width: 600px) {
    #searchbar {
     width: 70vw; 
    }
    #search {
      width: 100%;
      color: red;
    }
    .navbarSection{
      min-width: 35px;
      margin: 0px 16px;
    }
    button{
      width: 35px;
      margin: auto;
    }
  }
  