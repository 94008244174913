.fullscreen-enabled {
  .fullscreen-content {
    background: #fff;
  }
}

.table-responsive-group,
.table-responsive {
  width: 100%;
  border: 1px solid #ddd;
  font-size: 14px;

  table {
    width: 100%;
    border-collapse: collapse;

    th,
    td {
      text-align: left;
      padding: 12px;
    }

    tr {
      border-bottom: 1px solid #ddd;

      td:nth-child(1) {
        width: 70%;
      }

      td:nth-child(2) {
        width: 15%;
      }

      td:nth-child(3) {
        text-transform: capitalize;
        width: 15%;
      }
    }

    tr.header,
    tr:hover {
      background-color: #fafafa;
    }

    th {
      background-color: #f3f4f9;
    }

    a {
      color: #000000;
    }

    a:hover {
      text-decoration: none;
    }
  }

}

.usage-search-wrapper {
  padding-bottom: 10px;
}

.content-home,
.content-wrapper,
.tab-content-wrapper {

  .manage-button,
  .cart-icon {
    margin-left: 0.5rem;
    max-height: 50px;
    align-self: center;
  }

  button.manage-button {
    min-width: 6.5rem;
    padding: 0.5rem;
  }

  .result-row {

    .manage-button,
    .cart-icon {
      margin-left: 0;
    }
  }
}
