@import '../base/fonts';
@import '../base/global';
@import '../abstracts/colors';
@import '../abstracts/mixins';

.tags_wrapper {
  padding: 25px 25px 30px 25px;
  background-color: $text-clr-white;
  box-sizing: border-box;
  margin: 15px 0;

  @include respond-to('medium') {
    flex: 0 0 49%;
    width: 49%;
  }

  @include respond-to('ex-small') {
    flex: 0 0 100%;
    width: 100%;
    margin-bottom: 0px;
  }

  .tags_inner {
    .tag_inner_wrapper {
      gap: 10px;
      display: flex;
      flex-wrap: wrap;

      span {
        color: $text-clr-33;
        font-family: $Commissioner-SemiBold;
        border-radius: 9px;
        background-color: $bg-Link-Water;
        padding: 3px 10px 5px 10px;
        @extend .Commissioner-Medium14;
      }
    }
  }

  .tags_heading {
    color: $text-clr-33;
    font-family: $Commissioner-SemiBold;
    padding-bottom: 20px;
    @extend .Commissioner-Regular16;

    a {
      color: $text-clr-33;
      text-decoration: none;
    }
  }
}
