@import "../NextGen/Assets/sass/abstracts/colors";

.logout-page-bg {
  background-color: $bg-white;
  min-height: 100vh;

  .header-border-color {
    border-bottom: 1px solid $bg-Platinum-gray;

    .cursor-pointer {
      cursor: pointer;
    }
  }

  .logout-page-body {
    width: 94%;
    max-width: 1366px;
    display: flex;
    margin: auto;
    flex-direction: column;
    justify-content: space-between;

    .webform-share-iframe {
      width: 100%;
    }
  }
}
