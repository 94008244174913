.fav-btn {
  display: flex;

  .MuiIconButton-root {
    padding: 0 0;

    &:hover {
      padding: 0 0;
      background-color: transparent;
    }
  }

  img {
    width: 16px;
  }
}
