@import "../base/fonts";
@import "../base/global";
@import "../abstracts/colors";
@import "../abstracts/mixins";

.announcement_wrapper {
  background-color: $bg-white;
  box-sizing: border-box;

  &.group_announcement {
    margin: 15px 0px;

    @include respond-to("medium") {
      flex: 0 0 49%;
      width: 49%;
    }

    @include respond-to("ex-small") {
      flex: 0 0 100%;
      width: 100%;
      margin-bottom: 0px;
    }

    .announcement_title {
      @extend .Commissioner-Regular17;
      color: $heading-Dune-black-33;
      font-family: $Commissioner-SemiBold;
      padding: 24px 25px;
      border-bottom: 1px solid $bg-clr-E7;
      text-transform: capitalize;

      &.count_0 {
        border-bottom: none;
      }
    }
  }

  &.global_announcement {
    margin-top: 90px;

    @include respond-to("small") {
      padding-top: 10px;
      margin-top: 0;
    }

    .announcement_title {
      padding: 12px 20px;
      font-family: $Commissioner-Regular;
      @extend .Commissioner-Regular17;
      color: $text-clrE20;
      text-transform: capitalize;
    }

    .announcement_list_wrapper {
      padding: 0 20px 5px;
    }
  }

  .announcement_list_wrapper {
    padding: 0 10px 5px 25px;
    overflow-y: auto;
    max-height: 400px;
    margin-right: 5px;

    .announcement_list {
      padding: 15px 0;
      border-bottom: 1px solid $bg-Platinum-gray;
      font-family: $Commissioner-Regular;
      @extend .Commissioner-Regular15;
      color: $text-clr-33;

      &:last-child {
        border-bottom: none;
      }

      div {
        margin-bottom: 10px;
      }

      .date {
        color: $text-clr-mistblue-77;
      }

      .title {
        font-family: $Commissioner-Medium;
        @extend .Commissioner-Medium15;
      }

      .priority {
        font-family: $Commissioner-Medium;
        @extend .Commissioner-Medium15;
        color: $text-22-clr;
        display: flex;
        align-items: center;

        svg {
          font-size: 18px;
          color: $text-22-clr;
          padding-right: 5px;
        }
      }

      .announcement_message {
        color: $text-clr-33;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-line-clamp: 3;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        margin: 0 0 10px 0;

        >* {
          margin: 0;
        }
      }

      .viewfull {
        font-family: $Commissioner-Medium;
        @extend .Commissioner-Medium15;
        color: $text-clr-BC;
        text-decoration: none;
      }
    }
  }

  .announcement_viewall {
    color: $text-clr-BC;
    padding: 18px 0;
    text-align: center;
    border-top: 1px solid $bg-Platinum-gray;

    a {
      text-decoration: none;
      color: inherit;
      font-size: 15px;
    }
  }

  .create_announcement {
    text-align: center;
    padding: 0 20px 18px;

    button {
      width: 100%;
      text-transform: none;
      font-family: $Commissioner-Medium;
      @extend .Commissioner-Medium15;
      padding: 10px;
      white-space: normal;

      &:hover {
        background-color: $bg-white;
      }

      svg {
        font-size: 18px;
        margin-right: 5px;
      }
    }
  }
}
