/*------------opacity mixin--------*/
@mixin opacity($opacity) {
  opacity: $opacity;
  filter: alpha(opacity=($opacity * 100));
}

/*------------opacity mixin css code --------
div {
 @include opacity(0.5);
}
-----------------------------------*/

/*--------mixin bg----------*/
@mixin bgImage($image) {
  background: url($image);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-blend-mode: overlay;
}

/*------------End mixin bg-----------*/

$breakpoints: (
  'exx-small':414px,
  'ex-small':568px,
  'small': 767px,
  'medium': 991px,
  'large': 1280px) !default;

@mixin respond-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (max-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  }

  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}
