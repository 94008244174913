@import '../Assets/sass/base/fonts';
@import '../Assets/sass/base/global';
@import '../Assets/sass/abstracts/colors';
@import '../Assets/sass/abstracts/mixins';

.modal-wrapper {
  padding: 0;

  .close-modal {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;

    .MuiSvgIcon-root {
      color: $text-clr70;
      font-size: 19px;
    }
  }

  .add-member-modal-content {
    clear: both;
    padding: 30px;

    .heading {
      font-family: $Commissioner-Medium;
      color: $text-clr-33;
      @extend .Commissioner-Medium21;
      margin-top: 0px;
      font-weight: normal;
      margin-bottom: 20px;

      @include respond-to('small') {
        margin-bottom: 10px;
      }

      .border-right {
        margin: 0 10px;
        width: 1px;
        display: inline-block;
        color: $bdr-clr-c1c1;
      }

      .steps {
        @extend .Commissioner-Medium15;
        font-family: $Commissioner-Regular;
        color: $text-clr-33;
      }
    }

    .sub-heading {
      font-family: $Commissioner-Regular;
      @extend .Commissioner-Medium14;
      color: $text-clr-33;
      padding-bottom: 10px;

      @include respond-to('small') {
        padding-bottom: 10px;
      }
    }

    .member-form-wrapper {
      .form-email {
        font-family: $Commissioner-Medium;
        @extend .Commissioner-Medium15;
        padding-bottom: 10px;

        @include respond-to('small') {
          padding-bottom: 0px;
        }
      }

      .email-textarea {
        resize: none;
        width: 100%;
        color: $bg-gray-mist;
        padding: 10px;
        margin-top: 10px;
        border: 1px solid $bdr-clr-c1c1;
        font-family: $Commissioner-Regular;
        @extend .Commissioner-Regular14;
        border-radius: 3px;
        box-sizing: border-box;

        &.Mui-disabled {
          opacity: 0.4;
        }

        &:focus {
          border: 1px solid $text-clr-BC;
          outline: none;
        }

        &.fieldError {
          border: 1px solid $text-clrC1;
        }
      }

      .member-wrapper {
        display: flex;
        font-family: $Commissioner-SemiBold;
        color: $text-clr-33;
        padding: 23px 0px 23px 0px;
        flex-direction: column;
        @extend .Commissioner-Regular16;

        @include respond-to('small') {
          padding: 10px 0px 15px 0px;
        }

        .member-inner-section {
          display: flex;
          align-items: center;

          .switch-heading {
            padding-right: 30px;
          }
        }
      }

      .role-wrapper {
        .MuiFormGroup-root {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          gap: 50px;

          @include respond-to('small') {
            gap: 0px;
          }

          .PrivateSwitchBase-root-9 {
            margin-right: 7px;
          }
        }

        .MuiTypography-body1 {
          font-family: $Commissioner-Medium;
          color: $text-clr-33;
          @extend .Commissioner-Regular16;
        }

        .Mui-disabled {
          .MuiTypography-body1 {
            color: rgba(0, 0, 0, 0.26);
          }
        }

        .role-error {
          display: block;
          color: $text-clrC1;
          font-family: $Commissioner-Regular;
        }
      }

      .description-wrapper {
        padding: 10px 0px;

        .error-desc {
          padding-top: 6px;
          display: block;
          color: $text-clrC1;
          font-family: $Commissioner-Regular;
        }

        .fieldHeading {
          padding-bottom: 5px;
        }
      }
    }

    .message {
      span {
        display: flex;
        align-items: center;
        padding-bottom: 20px;
        font-family: $Commissioner-Medium;
        color: $text-clr7B;
      }
    }
  }

  .role-wrapper {
    .checkboxLabel {
      font-family: $Commissioner-Medium;
      @extend .Commissioner-Regular15;
    }
  }

  .fieldHeading {
    @extend .Commissioner-Regular15;
    padding-bottom: 8px;
    font-family: $Commissioner-Medium;

    &:not(.Mui-disabled) {
      color: $text-clr-33;
    }
  }

  .fieldError {
    z-index: 999;

    &:focus {
      z-index: 999;
    }
  }

  .fieldHasError {
    legend.fieldHeading {
      color: $text-clrC1;
    }
  }
}

/*----------------add member second step----------------*/
.add-member-step-2 {
  .table-wrapper {
    widtH: 100%;

    li:first-child {
      width: 30%;
    }

    li:nth-child(2) {
      width: 20%;
    }

    li:nth-child(3) {
      width: 41%;
    }

    li:nth-child(4) {
      width: 4%;
    }
  }

  .step-table {
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style: none;
    margin: 15px 0;
    padding: 0 0;
  }

  .table-header {
    margin: 10px 0px 0px 15px;
  }

  .table-details-wrapper {
    padding: 6px 15px;
    background-color: $bg-clr-FA;
    border: 1px solid $bg-clr-E7;
    margin: 14px 0px 10px 0px;
    max-height: 345px;
    overflow-y: auto;

    &::-webkit-scrollbar {
      background-color: $bg-White-SmokeF7;
      width: 15px;
    }

    &::-webkit-scrollbar-track {
      background-color: $bg-White-SmokeF7;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 20px;
      border: 4px solid $bg-White-SmokeF7;
      background-color: $bg-clr-d6;
    }

    input,
    select {
      background-color: $text-clr-white;
      border: 1px solid $bdr-clr-c1c1;
      height: 40px;
      width: 100%;
      padding: 10px;
      border-radius: 4px;
      box-sizing: border-box;
      font-family: $Commissioner-Regular;
      @extend .Commissioner-Medium14;
      color: $text-clr-33;

      &:focus {
        outline: none;
        border: 1px solid $text-clr-BC;
      }
    }

    .delete_row {
      cursor: pointer;
    }

    .error-input {

      input,
      select {
        border: 1px solid $text-22-clr;
      }

    }

    .error-msg {
      color: $text-22-clr;
      font-family: $Commissioner-Regular;
      @extend .Commissioner-Medium14;
      margin: 0 0;
      position: relative;
      top: -5px;
    }
  }
}

/*---------------------Common css  for all the form button------------*/
.btn-wrapper-email {
  display: flex;
  margin-top: 30px;
  justify-content: flex-end;

  @include respond-to('small') {
    margin-top: 15px;
  }

  &.step-2 {
    flex-direction: column;
  }

  .all-error {
    color: $text-22-clr;
    font-family: $Commissioner-Regular;
    @extend .Commissioner-Medium14;
    margin: 0 0;
    position: relative;
    top: -30px;
  }

  button {
    padding: 13px 20px;
    min-height: unset;
    line-height: 1;
    color: $text-clr-BC;
    font-family: $Karbon-Medium;
    border: 1px solid $text-clr-BC;
    border-radius: 4px;
    text-transform: capitalize;
    @extend .Karbon-Medium18;
    white-space: nowrap;
    box-shadow: none;

    &:hover {
      box-shadow: none;
    }
  }

  .MuiButton-outlinedPrimary,
  .MuiButton-outlinedPrimary:hover {
    margin-right: 20px;
    color: $text-clr-BC;
    background-color: $text-clr-white;
  }

  .MuiButton-containedPrimary,
  .MuiButton-containedPrimary:hover {
    background-color: $text-clr-BC;
    color: $text-clr-white;
  }

  .MuiButton-contained.Mui-disabled {
    border-color: transparent;
  }

  .add_member_wrapper {
    display: flex;
    justify-content: space-between;
    flex: 100%;

    @include respond-to('small') {
      flex-direction: column;
    }

    .btn-section-bottom {
      @include respond-to('small') {
        display: flex;
        align-items: center;
        justify-content: flex-end;
      }
    }

    .add_member {
      p {
        color: $text-clr-BC;
        font-size: 14px;
        font-family: $Commissioner-SemiBold;
        cursor: pointer;
        margin: 0 0;
      }
    }
  }
}
