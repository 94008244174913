@import '../base/fonts';
@import '../base/global';
@import '../abstracts/colors';
@import '../abstracts/mixins';

.navbar-wrapper {
  background-color: $bg-white;
  border-bottom: 1px solid $bg-Platinum-gray;

  .navbar {
    width: 94%;
    margin: auto;
    display: flex;
    max-width: 1366px;
    align-items: center;
    justify-content: space-between;
    height: 70px;

    .search-wrapper {
      position: relative;

      @include respond-to('medium') {
        display: none;
      }

      input {
        width: 220px;
        height: 35px;
        background-color: $bg-Aqua-HazeF4;
        border: none;
        outline: none;
        padding-left: 30px;
      }

      .MuiSvgIcon-root {
        position: absolute;
        left: 40px;
        top: 27px;
        color: $text-clr-mistblue-77;
        font-size: 14px;
      }
    }

    .navbar-left {
      display: flex;
      align-items: center;

      .site-logo {
        margin-right: 30px;

        @include respond-to('large') {
          img {
            width: 160px;
          }
        }
      }

      .nav-items-wrapper {
        z-index: 1;

        @include respond-to('medium') {
          display: none;
          position: absolute;
          right: 7%;
          flex-direction: column;
          top: 45px;

          &.open {
            display: block;
          }
        }
      }

      li {
        font-size: 1rem;
        cursor: pointer;
        font-style: normal;
        font-variant: normal;
        font-weight: 400;
        padding-right: 25px;
        font-family: $Karbon-Medium;
        color: $text-clr-mistblue-77;
        line-height: 1.375rem;

        a.active {
          color: $text-clr-BC;
        }

        a {
          color: $text-clr-mistblue-77;
          text-decoration: none;

          &:hover,
          &:active,
          .active {
            color: $text-clr-BC;
          }
        }

        @include respond-to('medium') {
          display: block;
          border-bottom: 1px solid $text-clr8cc;
          text-align: center;
          width: 100%;
          padding: 5px 15px;
          background-color: $bg-white;
        }
      }

      li:last-child {
        @include respond-to('medium') {
          border-bottom: none;
        }
      }

      li:hover {
        color: $bg-French-Blue;
      }
    }

    .navbar-right {
      .nav-autocomplete-wrapper {
        padding-bottom: 16px;
      }

      ul {
        @extend .flex-center;

        li {
          padding-left: 30px;
        }

        .search-wrapper {
          padding: 16px 0px 0px 30px;
        }

        .Notification-icon {
          cursor: pointer;

          .MuiSvgIcon-root {
            font-size: 24px;
            color: $text-clr-BC;
          }
        }

        .person_icon {
          width: 34px;
          height: 34px;
          cursor: pointer;

          img,
          .css-i4bv87-MuiSvgIcon-root {
            width: 34px;
            height: 34px;
          }
        }

        .nav_person {
          @include respond-to('medium') {
            padding-left: 0px;
          }
        }

        .phone_menuicon {
          display: none;

          .close-nav-icon {
            display: none;
          }

          .nav-icon {
            display: block;
          }

          &.close {
            .close-nav-icon {
              display: block;
            }

            .nav-icon {
              display: none;
            }
          }

          .MuiSvgIcon-root {
            font-size: 23px;
          }

          @include respond-to('medium') {
            display: block;
            font-size: 27px;
            color: $bg-gray-Mountain-Mist;
            padding-left: 15px;
          }
        }
      }
    }
  }
}
