@import '../../NextGen/Assets/sass/base/fonts';
@import '../../NextGen/Assets/sass/base/global';
@import '../../NextGen/Assets/sass/abstracts/colors';
@import '../../NextGen/Assets/sass/abstracts/mixins';

.facetdetails {
  margin-top: 10px;
  width: 100%;
  box-sizing: border-box;
  padding: 0px;
  box-shadow: none;
  background-color: transparent;

  .facet-box {
    padding: 0 0 10px 0;

    .checkbox-wrapper {
      background: $text-clr-white;
      box-shadow: none;
      width: 100%;
      padding: 5px 5px 20px 8px;
      box-sizing: border-box;

      @include respond-to('medium') {
        width: 100%;
      }

      .MuiFormControlLabel-label {
        @extend .Commissioner-Regular15;
        font-family: $Commissioner-Medium;
        font-size: 1rem !important;
        color: $bg-Dune-back;
      }

      .List {
        .formcontrollbl {
          width: 100%;
          margin: 0;
          display: flex;
          flex-flow: row nowrap;

          .MuiCheckbox-root {
            float: left;
          }

          .MuiFormControlLabel-label {
            font-size: 13px;
            word-wrap: break-word;
            width: 170px;
          }
        }

        &::-webkit-scrollbar {
          width: 5px;
        }

        &::-webkit-scrollbar-thumb {
          border: 1px;
        }
      }

      .loader-wrapper {
        width: 100%;

        h5 {
          padding: 5px 15px;
          margin: 0px;
        }
      }
    }

    .facetid-heading {
      background-color: $text-clr-white;
      margin: 0;
      padding: 20px 20px 0px 20px;
      font-family: $Commissioner-SemiBold;
      @extend .Commissioner-Regular16;
      color: $bg-Dune-back;
      display: block;
    }

    .switch-wrapper {
      background-color: $text-clr-white;
      padding: 10px 20px;
      display: flex;
      font-family: $Commissioner-SemiBold;
      justify-content: space-between;
      align-items: center;
    }

    .datehandler {
      &:focus {
        color: $text-clr91;
      }
    }

    .date-label {
      width: 100%;
      display: block;
      padding: 10px 0 0;
      font-size: 13px;
      font-weight: bold;
    }

    .searchfacet-wrapper {
      padding: 10px 10px 5px 10px;
      background-color: $text-clr-white;

      .MuiSvgIcon-root {
        font-size: 15px;
      }

      input {
        font-family: $Commissioner-Regular;
      }
    }

    .date-wrapper {
      background: $text-clr-white;
      box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
      padding: 0 12px 10px;

      &:first-child {
        margin-bottom: 10px;
      }
    }
  }
}

.dummy {
  font-size: 1rem;
  word-wrap: break-word;
  width: 256px;
  @extend .Commissioner-Regular15;
  font-family: $Commissioner-Medium;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  left: -999px;
  top: -999px;
}

.search_new_filter {
  .search-filter {
    background-color: $text-clr-white;
  }

  .search-top-filter {
    color: $bg-Dune-back;
    font-family: $Commissioner-Medium;
    @extend .Karbon-Medium18;
    padding-bottom: 4px;
  }

  .search_inner_filter {
    .search-facet-wrapper {
      padding: 20px;
      box-sizing: border-box;
      background-color: $text-clr-white;
      width: 100%;

      .MuiFormControlLabel-root {
        .MuiTypography-root {
          @extend .Commissioner-Regular15;
          font-family: $Commissioner-Medium;
          font-size: 1rem !important;
          color: $bg-Dune-back;
        }
      }

      .heading-radio {
        background-color: $text-clr-white;
        font-family: $Commissioner-SemiBold;
        @extend .Commissioner-Regular16;
        color: $bg-Dune-back;
        padding-bottom: 5px;
      }
    }
  }
}
