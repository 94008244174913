.dialog-modal {
  .close-icon {
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 20px;
  }

  .webform-share-iframe {
    width: 1px;
    min-width: 100%
  }
}

.dialog-modal:has(.loader) {
  overflow: hidden;
}
