.content-wrapper {
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 90%;
    margin-left: auto;
    margin-right: auto;
    padding: 2rem;
}

.header-wrapper{
    display: flex;
    flex-direction: row;
}

.content-title{
    margin-left: auto;
    margin-right: auto;
    font-size: 0.5rem;
}

.search-bar-wrapper{
    display:flex;
    justify-content: center;
    margin-bottom: 1rem;
}

.table-wrapper{
    display: flex;
    width: 55%;
    flex-direction: column;
}

.result-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.result-row {
    display: flex;
    width: 100%;
    flex-direction: row;
    margin-bottom: 2rem;
}
.result-item-wrapper {
    width: 65%;
    margin-right: 2rem;
}

.button-wrappers {
    flex-direction: column;
}

@media(max-width: 600px) {
    .content-wrapper{
        padding: 1rem;
    }
    .title-row {
        display:flex;
        font-weight: bold;
        justify-content: space-between;
        flex-direction: column;
    }
    .filter-wrapper--left-column{
        margin-bottom: 1.5rem;
        width: 25%;
        margin-left: 0;
        margin-right: 0.25rem;
    }
    .button-wrapper {
        min-width: 80px;
        margin-left: 0.25rem;
        margin-right: 0.25rem;
        button > span > img {
            max-width: 100%;
            justify-content: center;
            align-items: center;
            display: flex;
            object-fit: scale-down;
        }
    }
    .field-wrapper {
        margin-left: 0.25rem;
        margin-right: 0.25rem;
    }
    .button-wrappers {
        margin-left: 0.25rem;
    }
    .result-wrapper {
        flex-direction: column;
        margin-left: 1rem;
    }
    .result-row {
        justify-content: space-evenly;
    }
    .table-wrapper {
        width: 90%;
        justify-content: space-evenly;
    }
}

