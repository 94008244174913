@import '../base/fonts';
@import '../base/global';
@import '../abstracts/colors';
@import '../abstracts/mixins';

.page_header_wrapper {
  background-color: $bg-white;
  border-bottom: 1px solid $bg-Platinum-gray;
  position: relative;

  .fab_details_alert {
    position: absolute;
    top: 0px;
    width: 100%;

    .MuiAlert-standardSuccess {
      color: $text-B0F-clr;
      background-color: $bg-success;
    }

    .MuiPaper-root,
    .MuiAlert-message {
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $Commissioner-Medium;
      @extend .Commissioner-Regular14;

      a {
        color: inherit;
      }
    }
  }

  .breadcrumb_section_wrapper {
    width: 94%;
    margin: auto;
    @extend .max_width1366;

    .breadcrumb_section {
      display: flex;
      padding: 20px 0px 5px;
      flex-wrap: wrap;
      margin: auto;
      color: $text-clr7B;

      .breadcrumb_icon {
        padding: 0 3px;
        display: flex;
        align-items: center;

        .MuiSvgIcon-root {
          font-size: 1rem;
        }
      }

      .breadcrumb_item {
        @extend .Commissioner-Medium14;
        color: $text-clr7B;
        text-decoration: none;
        list-style: none;
        font-family: $Commissioner-Regular;
        display: flex;
        align-items: center;
        padding-bottom: 5px;

        a {
          @extend .Commissioner-Medium14;
          color: $text-clr7B;
          text-decoration: none;
          list-style: none;
          font-family: $Commissioner-Regular;

          &:hover {
            color: $bg-French-Blue;
          }
        }
      }
    }
  }

  .page_header_inner {
    width: 94%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    padding: 9px 0px 29px 0px;
    @extend .max_width1366;

    @include respond-to('exx-small') {
      flex-direction: column;
    }

    .page_header_heading {
      font-size: 1.75rem;
      font-family: $Karbon-Medium;
      color: $text-clrE20;

      @include respond-to('exx-small') {
        padding-bottom: 10px;
      }
    }

    .page_header_subheading {
      font-family: $Karbon-Regular;
      font-size: 20px;
      margin-top: 7px;
      color: $text-clr-mistblue-77;
      @include respond-to('small') {
        font-size: 13px;
        margin-bottom: 10px;
      }
    }

    .count_action_wrapper {
      display: flex;

      .fav-btn {
        padding-left: 25px;
      }

      .action-menu-wrapper {
        padding-left: 25px;
        display: flex;
        align-items: center;

        button {
          background-color: $text-clr-BC;
          width: 100%;
          white-space: nowrap;
          font-family: $Karbon-Medium;
          text-transform: capitalize;
          font-size: 18px;
        }

        span {
          font-family: $Commissioner-Regular;
        }
      }

      span {
        font-family: $Commissioner-Regular;
      }
    }

    .button_wrapper {
      @include respond-to('exx-small') {
        padding-top: 5px;
      }

      a {
        @extend .Karbon-Medium18;
        color: $text-clr-BC;
        font-family: $Karbon-Medium;
        padding: 13px 20px;
        border: 1px solid $text-clr-BC;
        text-decoration: none;
        border-radius: 4px;
      }
    }
  }

  .data_count_innerleft {
    display: flex;
    align-items: center;
    color: $text-clr7B;
    font-size: 0.875rem;
    font-family: $Commissioner-Medium;

    .data_count {
      display: flex;
      align-items: center;
      white-space: nowrap;
    }

    .data_statics {
      padding-left: 20px;
      display: flex;
      align-items: center;
      white-space: nowrap;
    }

    img {
      padding-right: 8px;
      width: 14px;
    }
  }
}
