.crsac-wrapper {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 2em;
  padding-left: 2em;
  padding-right: 2em;
  font-size: 1vw;

  .crsac-title {
    padding-right: 1em;
    font-size: 2rem;
    display: flex;
  }
}

.crsac-company-name {
  display: flex;
  flex-direction: row;
}

.crsac-star-rating {
  display: flex;
  flex-direction: row;

  .star-rating-text {
    padding-left: 0.5rem;
    display: flex;
    font-size: 1.5rem;
  }
}

.content-image {
  width: 5%;
  height: 5%;
  padding-right: 1em;
}
