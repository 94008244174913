div.search-facet-wrapper {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 1.5rem;

  &:not(:first-child) {
    display: block;
  }
}

div.search-facet-wrapper:last-child {
  margin-bottom: 0rem;
}
