div.popup {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  max-width: 60%;
  min-height: 3em;
  margin-top: 20%;
  z-index: 5;
}

p.popup-body {
  padding: .7em;
  padding-top: 0px;
}

h5.popup-title {
  padding: .5em;
}

button.popup-close-btn {
  float: right;
}

.text__color-janssen-blue {
  color: #00417B;
}

.send-all__no-bullet {
  list-style-type: none;
}
