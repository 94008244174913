@import '../Assets/sass/base/fonts';
@import '../Assets/sass/base/global';
@import '../Assets/sass/abstracts/colors';
@import '../Assets/sass/abstracts/mixins';

.search_section_wrapper {
  padding: 20px 0px;
  background-color: $text-clr-white;

  div.search-result-row {
    padding: 20px;
    border-bottom: 1px solid $bg-Platinum-gray;
    position: relative;

    a {
      text-decoration: none;
    }

    &:last-child {
      border: none;
      padding-bottom: 0px;
    }

    .search-result-row-footer {
      display: flex;
      justify-content: space-between;
      padding-top: 30px;

      a,
      p {
        @extend .Commissioner-Regular14;
        font-family: $Commissioner-Regular;
        text-decoration: none;
        color: $text-clr7B;
      }

      a {
        &:hover {
          color: $text-clr-BC;
        }
      }

      .breadcrumb-wrapper {
        display: flex;

        button {
          background-color: inherit;
        }
      }

      .details-link {
        a {
          color: $text-clr-BC;
          white-space: nowrap;
          padding-left: 20px
        }
      }
    }
  }

  .search_heading {
    @extend .Karbon-Regular22;
    color: $text-clr-33;
    font-family: $Karbon-Semibold;
    padding-bottom: 0px;
    padding-left: 20px;
  }

  .search_inner_section {
    display: flex;
    justify-content: space-between;

    .outer_section_left {
      width: 20%;
      max-width: 150px;
      max-height: 85px;
      background-color: $bg-clr-E1;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .outer_section_right {
      width: 80%;
      padding: 0px 10px 0px 20px;

      &.g_section {
        width: 100%;
        padding: 0px 0px 0px 0px;

        .section_vision {
          .item_list {
            display: list-item;
            list-style-position: inside;
            padding-right: 10px;

            &:first-child {
              display: inline-block;
            }

            span {
              position: relative;
            }
          }
        }

        .section_details {
          justify-content: flex-start !important;
        }
      }

      .section_left {
        .sections {
          justify-content: space-between;
          display: flex;
          padding-bottom: 10px;

          .section_lbl {
            color: $text-clr-white;
            display: inline-block;
            padding: 4px 8px;
            border-radius: 3px;
            @extend .Commissioner-Regular10;
            line-height: 12px;
            height: 12px;
          }
        }

        .section_vision,
        .section_details {
          @extend .Commissioner-Regular15;
          color: $text-clr-33;
          font-family: $Commissioner-Regular;
          display: flex;
          align-items: center;
          line-height: 18px;

          .long_text {
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            overflow: hidden;
            margin: 0px;
            line-height: 22px;
            font-family: $Commissioner-Regular;
            font-weight: 100;
            padding-left: 0px;
            width: 98%;

            >*:first-child {
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 1;
              -webkit-box-orient: vertical;
              overflow: hidden;
              margin: 0px;
              @extend .Commissioner-Regular15;
              line-height: 22px;
              font-family: $Commissioner-Regular;
              font-weight: 100;
              padding-left: 0px;
              width: 98%;
            }

            >*:nth-child(n+2) {
              display: none;
            }

            * {
              width: auto;
              margin: 0;
              max-width: 98%;
            }
          }

          .section-inside {
            display: flex;
            align-items: center;
            width: 90%;
          }

          .bld {
            font-family: $Commissioner-Medium;
            display: inline-block;
            padding-right: 5px;
            word-break: keep-all;
          }
        }

        .section_details {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding-top: 5px;

          .lbl_gxp {
            display: inline-block;
            color: $text-clr-33;
            background-color: $bg-clr-E7;
            padding: 10px 8px 12px 8px;
            border-radius: 9px;
            line-height: 0px;
            font-size: 0.75rem;
            font-family: $Commissioner-SemiBold;
            position: absolute;
            right: 30px;
          }
        }
      }

      .heading {
        @extend .Karbon-Regular22;
        color: $text-clr-33;
        font-family: $Karbon-Medium;
        margin-bottom: 0px;
        cursor: pointer;
      }
    }

    .error-message,
    .suggestion-header {
      @extend .Karbon-Regular22;
      color: $text-clr-33;
      font-family: $Karbon-Medium;
      margin-bottom: 0px;
      padding: 0px 20px;

      &.unexpected-error {
        display: block;
      }
    }

    .suggestion-header {
      @extend .Commissioner-Medium21;
      padding-left: 0;
    }

    .suggestion {
      @extend .Karbon-Medium18;
      margin-bottom: 2px;
      font-family: $Commissioner-Regular;
    }
  }
}

.search-results {
  &.no-result-wrapper {
    .MuiTypography-root {
      padding-bottom: 15px;
      color: $bg-Dune-back;
      font-family: $Commissioner-Medium;
      @extend .Karbon-Medium18;
    }
  }

  .see_all_results {
    text-align: center;
    padding: 20px 0 25px 0;
    background-color: $text-clr-white;
    margin-bottom: 10px;
    border-top: 1px solid $bg-Platinum-gray;

    span {
      color: $text-clr-BC;
      font-family: $Karbon-Medium;
      @extend .Karbon-Medium18;
      cursor: pointer;
    }
  }

  .content-wrapper.loader {
    min-height: inherit;
  }

  &.loader-section-0 {
    padding-top: 46px;

    @include respond-to('medium') {
      padding-top: 20px;
    }
  }
}

.search_gray_bg_wrapper {
  background-color: $bg-White-SmokeF7;
  float: left;
  width: 100%;

  .search_gray_bg {
    margin: auto;
    padding: 0px 15px;
    box-sizing: border-box;
    max-width: 1085px;
    margin-top: 40px;

    .bulkButtonDiv {
      .MuiTypography-root {
        color: $bg-Dune-back;
        font-family: $Commissioner-Medium;
        @extend .Karbon-Medium18;
        padding-bottom: 20px;
      }
    }
  }
}

.search_box_section_wrapper {
  padding-bottom: 2rem;

  .search-wrapper {
    height: 60px;
    background-color: $bg-white;
    display: inline-flex;
    border-radius: 5px;
    width: 100%;
    box-sizing: border-box;
    padding: 5px 5px 5px 20px;
    justify-content: space-between;

    .input-box {
      width: 100%;
      @extend .Commissioner-Regular16;
      color: $text-clr-33;
      font-family: $Commissioner-Regular;
      padding-left: 10px;
    }

    .search-btn {
      background-color: $text-clr-BC;
      color: $text-clr-white;
      margin: 0 0;
      @extend .Commissioner-Medium16;
      border: none;
      width: 148px;
      border-radius: 5px;
      font-family: $Commissioner-Regular;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      .MuiSvgIcon-root {
        font-size: 20px;
        padding-right: 15px;
        position: relative;
        top: 2px;
      }
    }
  }
}

/*--------------------------Search-Autocomplete----------------------*/
.MuiAutocomplete-popper {

  .MuiAutocomplete-paper {
    background-color: $text-clr-white;
    margin-top: 1px;

    .MuiAutocomplete-listbox {
      padding: 8px 0;
      max-height: 235px;
      box-shadow: 0px 7px 15px #00000014;

      &::-webkit-scrollbar {
        background-color: $text-clr-boulder-77;
        width: 5px
      }

      &::-webkit-scrollbar-track {
        background-color: $bg-white;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 20px;
        border: 3px solid $text-clr-boulder-77;
        background-color: $text-clr-boulder-77;
      }

      .MuiAutocomplete-option {
        @extend .Commissioner-Regular14;
        font-family: $Commissioner-Regular;
        background-color: $bg-white;
        padding: 7px 16px 7px 20px;
        min-height: auto;
        display: block;

        &:hover,
        &.Mui-focused {
          color: $text-clr-BC;
          background-color: inherit;
        }
      }

      .result_count {
        padding: 15px 0 10px;
        border-top: 1px solid $bg-Platinum-gray;
        text-align: center;
        font-family: $Commissioner-Medium;
        @extend .Commissioner-Medium16;
        color: $text-clr-BC;
        cursor: pointer;

        &.count_0 {
          color: $text-clr-99;
          cursor: unset;
        }
      }
    }
  }
}
