.inbox-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.inbox-button__highlight {
  font-weight: bold;
  color: #3f51b5;
}

.inbox-button-wrapper {
  display: flex;
  justify-content: space-between;
}

.star-rating-header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.inbox-process-display {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.select__full-width {
  width: 100%;
}

.preview-process-link {
  text-decoration: none;
  margin-top: 0.5rem;
  text-align: center;
}

.inbox-item-title__center {
  text-align: center
}

.action-buttons__space-between {
  justify-content: space-between;
}

.inbox-title-wrapper {
  margin: 1rem;

  .inbox-title__bold {
    font-weight: bold;
  }

  .inbox-data__blue {
    color: #3f51b5;
    text-decoration: none;
  }
}

.card-img__clickable {
  cursor: pointer;
}

.success-message__blue-highlight {
  color: #3f51b5;
  font-weight: bold;
}

.MuiBox-root {
  p {
    &.MuiTypography-root {
      &.MuiTypography-body1 {
        >div {
          display: flex;
          flex-wrap: wrap;

          &:after {
            visibility: hidden;
            display: block;
            font-size: 0;
            content: " ";
            clear: both;
            height: 0;
          }

          .MuiCardHeader-content {
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            height: 60px;
          }

          .inbox-title-wrapper {
            .MuiTypography-root {
              &.MuiTypography-body1 {
                &.MuiTypography-alignCenter {
                  &:nth-child(2),
                  &:nth-child(4) {
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                  }
                }
              }
            }
          }

          .MuiTablePagination-root {
            width: 100%;
            margin-top: 30px;

            .MuiTablePagination-toolbar {
              float: right;
            }
          }
        }
      }
    }
  }
}


