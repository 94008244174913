$Karbon-Bold: Karbon-Bold;
$Karbon-BoldItalic: Karbon-BoldItalic;
$Karbon-Hairline: Karbon-Hairline;
$Karbon-HairlineItalic: Karbon-HairlineItalic;
$Karbon-Light: Karbon-Light;
$Karbon-LightItalic: Karbon-LightItalic;
$Karbon-Medium: Karbon-Medium;
$Karbon-MediumItalic: Karbon-MediumItalic;
$Karbon-Regular: Karbon-Regular;
$Karbon-RegularItalic: Karbon-RegularItalic;
$Karbon-Semibold: Karbon-Semibold;
$Karbon-SemiboldItalic: Karbon-SemiboldItalic;
$Karbon-Thin: Karbon-Thin;
$Karbon-ThinItalic: Karbon-ThinItalic;
$Commissioner-Black: Commissioner-Black;
$Commissioner-Bold: Commissioner-Bold;
$Commissioner-ExtraBold: Commissioner-ExtraBold;
$Commissioner-ExtraLight: Commissioner-ExtraLight;
$Commissioner-Light: Commissioner-Light;
$Commissioner-Medium: Commissioner-Medium;
$Commissioner-Regular: Commissioner-Regular;
$Commissioner-SemiBold: Commissioner-SemiBold;
$Commissioner-Thin: Commissioner-Thin;


@font-face {
  font-family: $Karbon-Bold;
  src: url("~/public/fonts/Karbon/Karbon-Bold.otf");
  letter-spacing: 0px;
  line-height: 32px;
}

@font-face {
  font-family: $Karbon-BoldItalic;
  src: url("~/public/fonts/Karbon/Karbon-BoldItalic.otf");
}

@font-face {
  font-family: $Karbon-Hairline;
  src: url("~/public/fonts/Karbon/Karbon-Hairline.otf");
}

@font-face {
  font-family: $Karbon-HairlineItalic;
  src: url("~/public/fonts/Karbon/Karbon-HairlineItalic.otf");
}

@font-face {
  font-family: $Karbon-HairlineItalic;
  src: url("~/public/fonts/Karbon/Karbon-HairlineItalic.otf");
}

@font-face {
  font-family: $Karbon-Light;
  src: url("~/public/fonts/Karbon/Karbon-Light.otf");
}

@font-face {
  font-family: $Karbon-LightItalic;
  src: url("~/public/fonts/Karbon/Karbon-LightItalic.otf");
}

@font-face {
  font-family: $Karbon-Medium;
  src: url("~/public/fonts/Karbon/Karbon-Medium.otf");
}

@font-face {
  font-family: $Karbon-MediumItalic;
  src: url("~/public/fonts/Karbon/Karbon-MediumItalic.otf");
}

@font-face {
  font-family: $Karbon-Regular;
  src: url("~/public/fonts/Karbon/Karbon-Regular.otf");
  line-height: 30px;
  letter-spacing: -10px;
  font-size: 2.2rem;
}

@font-face {
  font-family: $Karbon-RegularItalic;
  src: url("~/public/fonts/Karbon/Karbon-RegularItalic.otf");
}

@font-face {
  font-family: $Karbon-Semibold;
  src: url("~/public/fonts/Karbon/Karbon-Semibold.otf");
  line-height: 41px;
  letter-spacing: -10px;
  font-size: 5.1rem;
}

@font-face {
  font-family: $Karbon-SemiboldItalic;
  src: url("~/public/fonts/Karbon/Karbon-SemiboldItalic.otf");
}

@font-face {
  font-family: $Karbon-Thin;
  src: url("~/public/fonts/Karbon/Karbon-Thin.otf");
}

@font-face {
  font-family: $Karbon-ThinItalic;
  src: url("~/public/fonts/Karbon/Karbon-ThinItalic.otf");
}

@font-face {
  font-family: $Commissioner-Bold;
  src: url("~/public/fonts/Commissioner/Commissioner-Bold.ttf");
}

@font-face {
  font-family: $Commissioner-ExtraBold;
  src: url("~/public/fonts/Commissioner/Commissioner-ExtraBold.ttf");
}

@font-face {
  font-family: $Commissioner-ExtraLight;
  src: url("~/public/fonts/Commissioner/Commissioner-ExtraLight.ttf");
}

@font-face {
  font-family: $Commissioner-Medium;
  src: url("~/public/fonts/Commissioner/Commissioner-Medium.ttf");
  line-height: 19px;
  letter-spacing: -10px;
  font-size: 1.6rem;
}

@font-face {
  font-family: $Commissioner-Regular;
  src: url("~/public/fonts/Commissioner/Commissioner-Regular.ttf");
  line-height: 23px;
  letter-spacing: 0px;
}

@font-face {
  font-family: $Commissioner-SemiBold;
  src: url("~/public/fonts/Commissioner/Commissioner-SemiBold.ttf");
}

@font-face {
  font-family: $Commissioner-Thin;
  src: url("~/public/fonts/Commissioner/Commissioner-Thin.ttf");
}
