.esp-wrapper {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 2em;
  padding-right: 2em;
}

.esp-title {
  padding-right: 1em;
  font-size: 2rem;
  display: flex;
}

.esp-company-name {
  display: flex;
  flex-direction: row;
}

.esp-star-rating {
  padding-left: 0.7em;
  padding-top: 0.7em;
}

.esp-website {
  padding-left: 10px;
  padding-top: 2px;
}

.content-home-wrapper {
  display: flex;
  flex-direction: row;
}

.header--esp {
  width: 100%;
  height: 30vh;
  object-fit: contain;
}

.header--esp-wrapper {
  display: flex;
  justify-content: center;
}

.content-home-info {

  &--space-apart,
  &--right-column {
    .fieldCategory {
      color: #3f51b5;
      font-size: 1.5rem;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: bold;
      line-height: 1.334;
      letter-spacing: 0em;
    }

    .fieldTitle {
      padding-left: 20px;
      font-size: 1rem;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: bold;
      line-height: 1.5;
      letter-spacing: 0em;
    }

    .field {
      padding-left: 20px;
      color: #3f51b5;
      font-size: 1rem;
      font-family: "Roboto", "Helvetica", "Arial", sans-serif;
      font-weight: 400;
      line-height: 1.5;
      letter-spacing: 0.00938em;
    }
  }

}
