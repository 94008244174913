.home-container {
  width: 95%;
  margin-left: auto;
  margin-right: auto;
}
.card-row {
  display: flex;
  flex-direction: row;
  margin-bottom: 30px;
  background: #fafafa;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  padding: 0 0 20px;
  border-radius: 10px;
  border: 1px solid #888;
  align-items: center;
}

.logo-image {
  width: 15rem;
  height: 15rem;
}

.home-message--top {
  color: white;
  font-size: 48px;
  align-self: center;
}
.home-message--bottom {
  color: white;
  font-size: 32px;
  align-self: center;
}
.link {
  color: white;
  font-size: 32px;
  align-self: center;
  text-decoration: underline;
  cursor: pointer;
}
.message-container {
  display: flex;
  flex-flow: column;
  margin-top: 1rem;
  margin-bottom: 1rem;
  width: 85%;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

@media (max-width: 600px) {
  .logo-image {
    display: none;
  }
  .card-row {
    flex-direction: column;
  }
}

.tabs-main {
  .tabs-wrapper {
    .tabs-title {
      flex-grow: 1;
      max-width: none;
      flex-basis: 0;
      flex-shrink: 1;
    }
  }
}

.tab-details {
  .grid-img {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      width: 35px;
      height: 35px;
    }
  }

  .grid-text {
    padding-left: 20px;
    font-size: 1.1rem;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    color: #333;
  }
}

.separator {
  position: relative;
}

.separator-transparent:before {
  content: '';
  display: block;
  width: 90%;
  height: 1px;
  background-image: linear-gradient(
    to right,
    transparent,
    rgb(30, 34, 170),
    transparent
  );
  margin: 0 auto;
}
.user-info-wrapper {
  width: 100%;
  height: auto;
  min-height: 100vh;
  padding-top: 50px;
  display: flex;
}
.profile-card {
  width: 100%;
  min-height: 460px;
  margin: auto;
  box-shadow: 0px 8px 60px -10px rgba(13, 28, 39, 0.6);
  background: #fff;
  border-radius: 12px;
  max-width: 85%;
  position: relative;
  &.active {
    .profile-card__cnt {
      filter: blur(6px);
    }
    .profile-card-form {
      transform: none;
      transition-delay: 0.1s;
    }
  }
}
.profile-card.active .profile-card-message,
.profile-card.active .profile-card__overlay {
  opacity: 1;
  pointer-events: auto;
  transition-delay: 0.1s;
}
.profile-card__img {
  width: 150px;
  height: 150px;
  margin-left: auto;
  margin-right: auto;
  transform: translateY(-50%);
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  z-index: 4;
  box-shadow: 0px 5px 50px 0px #6c44fc, 0px 0px 0px 7px rgba(107, 74, 255, 0.5);
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
}
.profile-card__cnt {
  margin-top: -35px;
  text-align: center;
  padding: 0 20px;
  padding-bottom: 40px;
  transition: all 0.3s;
}
.profile-card__name {
  font-weight: 700;
  font-size: 24px;
  color: #6944ff;
}
.profile-card__txt {
  font-size: 18px;
  font-weight: 500;
  color: #324e63;
  margin-bottom: 15px;
  strong {
    font-weight: 700;
  }
}
.profile-card-loc {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
}
.profile-card-loc__icon {
  display: inline-flex;
  font-size: 27px;
  margin-right: 10px;
}
.name-group-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

// **********************

.gridbox {
  .gridtable {
    padding: 10px;
  }

  .gridsidebar {
    padding-left: 15px;
    border-left: 1px solid #eee;

    .sidegrid {
      padding-top: 30px;

      &:first-child {
        padding-top: 0;
      }
    }
  }

  .gridcontent {
    padding-right: 15px;

    .gridcontent-area {
      .gridtable {
        float: left;
        width: 50%;
      }
    }
  }

  h2 {
    font-size: 1.5rem;
    font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
    font-weight: 400;
    line-height: 1.334;
    letter-spacing: 0em;
    padding-bottom: 10px;
    color: #1e22aa;
  }
}
