.result-modal-container__center-contents {
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.result-modal-contents {
  background-color: white;
  height: 75vh;
  width: 85vh;
  position: relative;
  overflow-y: scroll;
}

.result-modal-header {
  font-size: 2rem;
  display: flex;
  flex-direction: column;
  margin: 1rem;

  .title {
    text-align: center;
    color: #00417B;
    margin-bottom: 0.75rem;
  }

  .id-info {
    display: flex;
    justify-content: space-around;
    font-size: 1rem;
  }
}

.modal-close-button {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}

.result-modal--main-content {
  height: 80%;
  overflow-y: scroll;
  padding: 0 1rem;
}

svg.MuiSvgIcon-root.result-navigation-arrow {
  // Requires min weight 0021 to override.
  color: aliceblue;
  font-size: 5rem;
  cursor: pointer;

  &.hide_arrow {
    // Left the offset intact for the modal.
    visibility: hidden;
  }
}

.topic-wrapper {
  margin-bottom: 1rem;
}
.topic-title {
  padding-bottom: 0.75rem;
  font-weight: bold;
  font-size: 2rem;
}
.field {
  font-weight: bold;
  font-size: 1.5rem;
}
.field-item {
  font-size: 1.5rem;
  color: #00417B;
}

@media(max-width: 600px) {
  .field {
    font-size: 1.2rem;
  }
  .field-item {
    font-size: 1.2rem;
  }
  .topic-title {
    font-size: 1.5rem;
  }
}
