@import '../../Assets/sass/base/fonts';
@import '../../Assets/sass/base/global';
@import '../../Assets/sass/abstracts/colors';
@import '../../Assets/sass/abstracts/mixins';
@import '../../Assets/sass/base/grid';

.messages_right_section {
  margin-bottom: 10px;

  @include respond-to('ex-small') {
    width: 100%;
    clear: both;
  }

  .group_selection_wrapper {
    background-color: $text-clr-white;
  }

  .messages_section_wrapper {
    padding: 30px;
    background-color: $text-clr-white;
    border-radius: 4px;

    .MuiGrid-root {
      justify-content: space-between;

      @include respond-to('small') {
        flex-wrap: wrap;
      }

      .messages_section_heading {
        font-family: $Karbon-Regular;
        @extend .Karbon-Regular26;
        color: $text-clrE20;

        .messages_section_sub {
          p {
            font-family: $Commissioner-Regular;
            @extend .Commissioner-Medium14;
            font-weight: bold;
            font-size: 15px;
            color: $text-clr-BC;
            margin: 5px 0;
          }
        }
      }
    }
  }

  .announcementlist_wrapper {
    background-color: $text-clr-white;
    margin-top: 10px;

    &.no_top_gap {
      margin-top: 0px;
    }

    a {
      text-decoration: none;

      .announcementlist_item {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding: 25px 30px;
        font-family: $Commissioner-Medium;
        @extend .Commissioner-Medium15;
        color: $text-clr-33;
        border-bottom: 1px solid $bg-Platinum-gray;
        border-radius: 4px;

        @include respond-to('small') {
          flex-wrap: wrap;
          padding: 15px;
        }

        .details_name {
          color: $text-clr-33;
          flex: 0 1 12%;
        }

        .details_text_wrapper {
          display: flex;
          flex-direction: column;
          flex: 0 1 auto;
          width: 66%;
          white-space: nowrap;

          @include respond-to('small') {
            width: 70%;
          }

          .details_text {
            display: flex;
            align-items: center;
            margin-bottom: 5px;

            @include respond-to('small') {
              flex-wrap: wrap;
            }

            .priority {
              display: flex;
              align-items: center;
              color: $text-22-clr;
              padding-left: 4px;

              svg {
                padding-right: 5px;
                font-size: 18px;
                color: $text-22-clr;
              }
            }

            .status {
              padding-left: 5px;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }

          .message {
            font-family: $Commissioner-Regular;
            @extend .Commissioner-Regular15;
            font-weight: normal;
            color: $text-clr-mistblue-77;
            padding-left: 5px;
            text-overflow: ellipsis;
            display: inline;
            white-space: nowrap;
            overflow: hidden;
            margin-bottom: 5px;
          }

        }

        .details_date {
          display: flex;
          color: $text-clr-mistblue-77;
          align-items: center;

          @include respond-to('small') {
            justify-content: flex-end;
            flex: 1 1 auto;
          }

          img {
            margin-right: 6px;

            @include respond-to('small') {
              height: 11px;
            }
          }
        }

        .chip_wrapper {
          margin-bottom: 10px;

          .MuiChip-root {
            border-radius: 4px;
            border-color: $bg-Platinum-gray;
            margin: 0 5px;
            padding: 0 4px;

            .MuiChip-label {
              font-family: $Commissioner-Medium;
              @extend .Commissioner-Medium13;
              color: $text-clr-33;
            }

            svg {
              color: $text-clr-33;

              @include respond-to('small') {
                font-size: 12px;
              }
            }
          }
        }

        .tag_wrapper {

          .tag {
            display: inline-flex;
            font-family: $Commissioner-Medium;
            @extend .Commissioner-Medium13;
            color: $text-clr-white;
            padding: 2px 6px;
            border-radius: 3px;
            font-size: 10px;
            margin: 0 5px;

            &.global {
              background-color: $bg-black;
            }

            @include respond-to('small') {
              font-size: 8px;
            }
          }
        }
      }
    }

    &.no_results {
      font-family: $Commissioner-Regular;
      @extend .Commissioner-Regular17;
      color: $text-clr-mistblue-77;
      padding: 30px 0;
      text-align: center;
    }
  }

  // Announcement Full View CSS
  .announcement_fullview {
    padding: 30px;
    background-color: $bg-white;
    border: 4px;
    margin-top: 10px;

    &.no-access {
      font-family: $Commissioner-Regular;
      @extend .Commissioner-Regular17;
      color: $text-clr-mistblue-77;
      padding: 30px 0;
      text-align: center;
    }

    .fullview_title_wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 5px 0 25px;
      border-bottom: 1px solid $bg-Platinum-gray;
      font-family: $Commissioner-Medium;

      .title {
        display: flex;
        align-items: flex-start;
        @extend .Commissioner-Medium21;
        color: $text-clr-33;

        svg {
          color: $text-clr-99;
          margin-right: 10px;
          cursor: pointer;
        }
      }

      .date {
        @extend .Commissioner-Medium15;
        color: $text-clr-mistblue-77;
        display: flex;
        align-items: center;
        white-space: nowrap;

        img {
          margin-right: 6px;

          @include respond-to('small') {
            height: 11px;
          }
        }
      }
    }

    .fullview_details_wrapper {
      padding: 35px 10px 0 35px;

      @include respond-to('small') {
        padding: 20px 10px 0 10px;
      }

      .person_info {
        display: flex;
        align-items: center;

        .MuiAvatar-root {
          width: 34px;
          height: 34px;

          div {
            font-size: 15px;
          }

          img {
            width: 100%;
            height: auto;
          }
        }

        .name {
          font-family: $Commissioner-Medium;
          @extend .Commissioner-Medium15;
          color: $text-clr-33;
          margin-left: 15px;
        }

        .separator {
          margin: 0 5px;
          color: $text-clr70;
        }

        .email {
          font-family: $Commissioner-Regular;
          @extend .Commissioner-Regular15;
          color: $text-clr70;
        }
      }

      .priority {
        display: flex;
        align-items: center;
        color: $text-22-clr;
        padding-top: 10px;

        svg {
          padding-right: 5px;
          font-size: 18px;
          color: $text-22-clr;
        }
      }

      .description {
        font-family: $Commissioner-Regular;
        @extend .Commissioner-Regular15;
        color: $text-clr-33;
        padding: 25px 0;
        margin: 0;

        >*:first-child {
          margin-top: 0px;
        }

        >*:last-child {
          margin-bottom: 0px;
        }
      }

      .group_info {
        display: flex;
        flex-direction: column;
        font-family: $Commissioner-Regular;
        @extend .Commissioner-Regular15;
        color: $text-clr-mistblue-77;
        font-style: italic;

        .chip_wrapper {
          margin: 10px 0;
          font-style: normal;

          .MuiChip-root {
            border-radius: 4px;
            border-color: $bg-Platinum-gray;
            margin: 0 10px 0 0;
            padding: 0 4px;

            .MuiChip-label {
              font-family: $Commissioner-Medium;
              @extend .Commissioner-Medium13;
              color: $text-clr-mistblue-77;
            }
          }
        }
      }

      .tag_wrapper {
        margin: 10px 0;
        font-family: $Commissioner-Regular;
        @extend .Commissioner-Regular15;
        color: $text-clr-mistblue-77;
        font-style: italic;

        .tag_inner_wrapper {
          font-style: normal;
          margin: 10px 0;

          .tag {
            display: inline-flex;
            font-family: $Commissioner-Medium;
            @extend .Commissioner-Medium13;
            color: $text-clr-white;
            padding: 2px 6px;
            border-radius: 3px;
            font-size: 10px;
            margin: 0 10px 0 0;

            &.global {
              background-color: $bg-black;
            }

            @include respond-to('small') {
              font-size: 8px;
            }
          }
        }
      }
    }

    .attachments_wrapper {
      padding: 20px 10px 0 35px;
      border-top: 1px solid $bg-Platinum-gray;
      margin-top: 30px;

      @include respond-to('small') {
        padding: 20px 10px 0 10px;
      }

      .title {
        font-family: $Commissioner-Medium;
        @extend .Commissioner-Medium15;
        color: $text-clr-33;
      }

      .attachment_items {
        padding-top: 10px;
        display: flex;
        flex-wrap: wrap;
        width: 100%;

        .MuiCard-root {
          width: 275px;
          box-shadow: none;
          border-radius: 4px;
          border: 1px solid $bg-clr-DE;
          margin-right: 10px;
          margin-top: 10px;

          @include respond-to('medium') {
            margin-bottom: 10px;
            margin-right: 0;
          }

          .MuiCardMedia-root {
            background-color: #b8d8f1;
            height: 160px;
            background-size: auto;
          }

          .MuiCardContent-root {
            padding: 18px 20px 5px;
            border-radius: 0;

            div {
              font-family: $Commissioner-Regular;
              @extend .Commissioner-Regular14;
              color: $text-clrE20;
            }
          }

          .MuiCardActions-root {
            padding: 5px 20px 20px;

            p {
              font-family: $Commissioner-Medium;
              @extend .Commissioner-Medium14;
              color: $text-clr-BC;
              margin: 0;
              cursor: pointer;

              a {
                text-decoration: none;
                color: $text-clr-BC;
              }
            }
          }
        }
      }
    }

    .archive_wrapper {
      padding: 30px 0 0 35px;

      @include respond-to('small') {
        padding: 20px 0 0 10px;
      }

      button {
        text-transform: capitalize;
        white-space: nowrap;

        .MuiButton-startIcon {
          @include respond-to('ex-small') {
            margin-right: 0px;
          }

          @include respond-to('small') {
            margin-right: 4px;
          }
        }

        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}

/* Create Announcement Button*/
.create_announcement {
  display: flex;
  align-items: center;

  button {
    width: 100%;
    white-space: nowrap;
    text-transform: none;
    font-family: $Commissioner-Medium;
    @extend .Commissioner-Medium15;

    svg {
      font-size: 20px;
      margin-right: 5px;
    }
  }
}

.preview_modal {
  width: 45vw;
  min-height: 35vh;
  padding: 40px 40px 20px;
  border: 2px solid $bg-Platinum-gray;
  overflow: auto;
  outline: none;

  @include respond-to('small') {
    padding: 15px;
    width: 65vw;
    min-height: auto;
  }

  .preview_modal_user {
    display: flex;
    align-items: center;
    padding: 10px 0;

    .MuiAvatar-root {
      width: 34px;
      height: 34px;

      div {
        font-size: 15px;
      }

      img {
        width: 100%;
        height: auto;
      }
    }

    .owner_name {
      display: flex;
      flex-direction: column;
      margin-left: 15px;

      p {
        margin: 0;
        color: $text-clr-33;

        &.owner {
          font-family: $Commissioner-Medium;
          @extend .Commissioner-Medium15;
        }

        &.img_name {
          font-family: $Commissioner-Regular;
          @extend .Commissioner-Regular15;
          color: $text-clr70;
        }
      }
    }

    .close {
      position: absolute;
      top: 20px;
      right: 40px;
      cursor: pointer;

      @include respond-to('small') {
        right: 20px;
      }

      svg {
        color: $text-clr70;
      }
    }
  }

  img {
    padding: 20px 0;
    width: 100%;
    object-fit: contain;
    height: auto;
    border-bottom: 1px solid $bg-Platinum-gray;

    @include respond-to('small') {
      padding: 12px 0;
    }
  }

  .download_btn {
    margin-top: 20px;
    text-align: right;

    @include respond-to('small') {
      margin-top: 12px;
    }

    button {
      padding: 13px 20px;
      color: $text-clr-BC;
      border-color: $text-clr-BC;
      font-family: $Commissioner-Medium;
      @extend .Commissioner-Medium15;
      text-transform: none;
      background-color: $bg-white;
      white-space: nowrap;

      &:hover {
        border-color: $text-clr-BC;
        background-color: transparent;
      }
    }
  }
}

.MuiAutocomplete-popper .MuiAutocomplete-paper .MuiAutocomplete-listbox {
  &#my-groups-checkboxes-listbox {
    .MuiAutocomplete-option {
      display: flex;
      justify-content: space-between;
    }
  }
}

// Archive Modal for announcement.
.archive_modal {
  #archive-modal-title {
    font-family: $Commissioner-Medium;
    @extend .Commissioner-Medium21;
    color: $heading-Dune-black-33;
  }

  .modal-description {
    font-family: $Commissioner-Regular;
    @extend .Commissioner-Regular16;
    color: $text-22-clr;
    margin-top: 10px;

    &.top {
      font-family: $Commissioner-Medium;
      @extend .Commissioner-Medium16;
      color: $heading-Dune-black-33;
      margin-top: 20px;
    }

    &.second {
      color: $text-clr-BC;
      margin-bottom: 20px;
      margin-top: 5px
    }
  }

  .btn_archive {
    margin: 25px 0 15px 0;
    display: flex;
    justify-content: flex-end;

    button {
      padding: 13px 20px;
      min-height: unset;
      line-height: 1;
      border-radius: 4px;
      text-transform: capitalize;
      font-family: $Commissioner-Medium;
      @extend .Commissioner-Medium15;
      white-space: nowrap;
      box-shadow: none;

      &:hover {
        box-shadow: none;
      }
    }

    .MuiButton-outlinedPrimary,
    .MuiButton-outlinedPrimary:hover {
      margin-right: 20px;
      background-color: $bg-white;
    }
  }

  .close-icon {
    position: absolute;
    top: 15px;
    right: 20px;
    cursor: pointer;
  }
}
