@import '../base/fonts';
@import '../base/global';
@import '../abstracts/colors';
@import '../abstracts/mixins';

.about_detailspage_wrapper {
  .about_heading {
    color: $text-clr-33;
    font-family: $Commissioner-SemiBold;
    @extend .Commissioner-Regular14;
    padding: 16px 0 26px 0;
    font-size: 1.25rem;

    @include respond-to('small') {
      padding: 7px 0 17px 0;
    }
  }

  @include respond-to('medium') {
    flex: 0 0 100%;
    width: 100%;
  }

  .vision_wrapper {
    background-color: $bg-white;
    padding: 24px 25px 30px 25px;

    .vision {
      @extend .Commissioner-Regular15;
      color: $text-clr-33;
      font-family: $Commissioner-SemiBold;
    }

    .vision_text {
      color: $text-clrE20;
      @extend .Commissioner-Regular15;
      font-family: $Commissioner-Regular;
      overflow: hidden;

      *:first-child {
        margin-top: 10px;
      }

      *:last-child {
        margin-bottom: 0px;
      }

      &.limited_text {
        >*:first-child {
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          margin-bottom: 0px;
        }

        >*:nth-child(n+2) {
          display: none;
        }
      }
    }

    .vision_readmore {
      color: $text-clr-BC;
      @extend .Commissioner-Regular15;
      font-family: $Commissioner-Medium;
      text-decoration: none;
      cursor: pointer;
      display: flex;
      padding-top: 10px;
    }

    a {
      color: $text-clr-BC;
      text-decoration: none;
    }

    .inception_section,
    .last_updated,
    .application_owner,
    .application_history {
      display: flex;
      flex-direction: column;
      padding-top: 19px;

      &.life_cycle {
        padding-top: 0px;
      }

      .inception_text {
        color: $text-clr-33;
        @extend .Commissioner-Regular15;
        font-family: $Commissioner-SemiBold;
        padding-bottom: 5px;
        text-transform: capitalize;
      }

      .inception_date {
        color: $text-clr-33;
        @extend .Commissioner-Regular15;
        font-family: $Commissioner-Regular;
      }

      .owner_name,
      .revision_history {
        @extend .Commissioner-Medium15;
        font-family: $Commissioner-Medium;
        text-decoration: none;
        color: $text-clr-BC;
      }

      .bottom_padding {
        padding-bottom: 15px;
      }
    }
  }
}
