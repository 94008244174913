@import '../base/fonts';
@import '../base/global';
@import '../abstracts/colors';
@import '../abstracts/mixins';
@import '../base/grid';

#my-profile {
  .MuiPaper-root {
    border: none !important;

    ul {
      padding: 0 0;

      li {
        padding: 0 0;
        background-color: $bg-white;

        span.MuiTypography-root {
          display: inline-block;
          background: $text-clr-white 0% 0% no-repeat padding-box;
          border: none;
          @extend.Commissioner-Regular16;
          font-family: $Commissioner-Regular;
          color: $bg-Dune-back;
          padding: 18px 30px;
          width: 100%;
        }

        &:hover {
          background-color: transparent;

          span.MuiTypography-root {
            color: $bg-Dune-back;
          }
        }
      }
    }

    .MuiListItem-root.Mui-focusVisible {
      background-color: transparent;
    }
  }
}
