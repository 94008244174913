@import '../base/fonts';
@import '../base/global';
@import '../abstracts/colors';

.footer-outer-wrapper {
  background-color: $bg-white;
  clear: both;
  border-top: 1px solid $bg-Platinum-gray;

  .footer-inner-wrapper {
    max-width: 1366px;
    width: 94%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 80px;

    .footer-menu {
      li {
        @extend .Commissioner-Medium13;
        font-family: $Commissioner-Medium;
        padding-left: 30px;
        color: $text-clr-33;
        text-decoration: none;

        @include respond-to('exx-small') {
          padding-left: 14px;
        }
      }

      a {
        @extend .Commissioner-Medium13;
        font-family: $Commissioner-Medium;
        color: $text-clr-33;
        text-decoration: none;
        white-space: nowrap;
      }

      a.active {
        color: $text-clr-BC;
      }
    }

    @include respond-to('large') {
      .footer-logo {
        img {
          width: 160px;
        }
      }
    }
  }
}
