@import '../base/fonts';
@import '../base/global';
@import '../abstracts/colors';
@import '../abstracts/mixins';

.owned_wrapper {
  padding: 25px 25px 30px 25px;
  background-color: $text-clr-white;
  box-sizing: border-box;
  margin: 15px 0;

  @include respond-to('medium') {
    flex: 0 0 49%;
    width: 49%;
  }

  @include respond-to('ex-small') {
    flex: 0 0 100%;
    width: 100%;
    margin-bottom: 0px;
  }

  .owned_inner {
    &.shared {
      padding-top: 20px;
    }

    a {
      color: $text-clr-33;
      text-decoration: none;
    }
  }

  .owned_heading {
    color: $text-clr-33;
    font-family: $Commissioner-SemiBold;

    a {
      color: $text-clr-33;
      text-decoration: none;
    }
  }

  .owned_name {
    padding-top: 10px;
    color: $bg-French-Blue;
    font-family: $Commissioner-Medium;

    a {
      color: $bg-French-Blue;
      text-decoration: none;
    }
  }
}
