@import '../base/fonts';
@import '../base/global';
@import '../abstracts/colors';
@import '../abstracts/mixins';

.business_team_wrapper {
  padding: 26px 25px 30px 25px;
  margin: 15px 0px;
  box-sizing: border-box;
  background-color: $bg-white;

  @include respond-to('medium') {
    flex: 0 0 49%;
    width: 49%;
  }

  @include respond-to('ex-small') {
    flex: 0 0 100%;
    width: 100%;
    margin-bottom: 0px;
  }

  .business_heading {
    @extend .Commissioner-Regular17;
    color: $heading-Dune-black-33;
    font-family: $Commissioner-SemiBold;
  }

  .business_text {
    @extend .Commissioner-Medium13;
    font-family: $Commissioner-Regular;
    padding: 10px 0px;
  }

  .team_wrapper {
    padding-top: 3px;

    .team_details {
      @extend .flex-center;
      align-items: center;
      padding: 10px 0px;

      .team_icon_left {
        align-self: flex-start;
        max-width: 20%;

        img {
          width: 40px;
          height: 40px;
        }

        div {
          font-size: 1rem;
        }
      }

      .team_details_right {
        margin-left: 6%;
        max-width: 74%;

        .user_name {
          a {
            @extend .Commissioner-Regular14;
            font-family: $Commissioner-Medium;
            color: $text-clrE20;
            line-height: 1.3rem;
            text-decoration: none;
          }
        }

        .user_info {
          @extend .Commissioner-Regular14;
          color: $bg-gray-Boulder;
        }
      }
    }
  }

  .see_all {
    @extend .Commissioner-Regular15;
    color: $text-clr-BC;
    font-family: $Commissioner-Medium;
    text-align: center;
    cursor: pointer;
    padding-top: 10px;
  }

  .join {
    &.btn_button {
      padding-top: 20px;
    }
  }
}
