@import '../../../Assets/sass/base/fonts';
@import '../../../Assets/sass/base/global';
@import '../../../Assets/sass/abstracts/colors';
@import '../../../Assets/sass/abstracts/mixins';

.business-team {
  background-color: $bg-white;

  @include respond-to('large') {
    top: 20px;
    transform: translate(0%, 0%);
  }

  .content_team_wrapper {
    @extend .Commissioner-Medium21;
    color: $bg-Dune-back;
    font-family: $Commissioner-Medium;
    display: flex;
    justify-content: space-between;

    @include respond-to('ex-small') {
      display: block;
    }

    .team_wrapper-left {

      .content_heading {
        font-family: $Commissioner-SemiBold;
        @extend .Commissioner-SemiBold;
        font-size: 20px;
      }

      .content_subheading {
        font-family: $Commissioner-Regular;
        @extend .Commissioner-Regular14;
        color: $text-clr-mistblue-77;
        padding-top: 20px;
        padding-bottom: 9px;
      }
    }

    .team_wrapper_right {

      @include respond-to('ex-small') {
        margin-top: 15px;
      }

      &.join {
        font-family: $Commissioner-SemiBold;
        @extend .Commissioner-SemiBold;
        color: $text-clr-BC;
        letter-spacing: 0;
        cursor: pointer;
      }
    }
  }

  .tabs_wrapper {
    align-items: flex-start;

    > div:nth-of-type(odd) {
      background-color: $bg-clr-FA;
    }

    .tabs_section {
      padding: 0;
      list-style: none;
      display: flex;
      margin: 0;
      width: 100%;

      .tabs_item {
        width: 33%;
        padding: 2% 2%;
        box-sizing: border-box;
        overflow-wrap: break-word;

        @include respond-to('ex-small') {
          width: 30%;
        }
      }

      &.tabs_header {
        .tabs_item {
          font-family: $Commissioner-SemiBold;
          font-size: 13px;
          color: $text-clr-99;
          letter-spacing: -0.13px;
          padding: 16px 2%;

          &:first-child {
            padding-left: 0;
          }

          &:nth-child(2) {
            width: 17%;

            @include respond-to('medium') {
              width: 20%;
            }

            @include respond-to('ex-small') {
              width: 25%;
            }
          }

          &:last-child {
            width: 42%;

            @include respond-to('medium') {
              width: 37%;
            }

            @include respond-to('ex-small') {
              width: 34%;
            }
          }
        }
      }

      &.tabs_rows {
        .tabs_item {
          font-family: $Commissioner-Regular;
          @extend .Commissioner-Regular15;
          color: $text-clr-mistblue-77;

          &.right_align {
            text-align: right;
          }

          a {
            text-decoration: none;
            color: $text-clrE20;
            @extend .Commissioner-Medium14;
          }

          &:nth-child(2) {
            width: 17%;

            @include respond-to('medium') {
              width: 20%;
            }

            @include respond-to('ex-small') {
              width: 25%;
            }
          }

          &:last-child {
            width: 8%;

            @include respond-to('ex-small') {
              width: 11%;
              .MuiIconButton-root {
                width: 23px;
                padding: 4px;
              }
            }
            
          }

          &:nth-last-child(2) {
            width: 42%;

            @include respond-to('medium') {
              width: 37%;
            }

            @include respond-to('ex-small') {
              width: 34%;
            }
          }
        }
      }

      .team_details_right {
        margin-left: 7%;
        max-width: 77%;

        @include respond-to('medium') {
          margin-left: 5%;
        }

        @include respond-to('small') {
          max-width: 65%;
        }

        @include respond-to('ex-small') {
          margin-left: 0;
          max-width: 100%;
        }

        .user_name {
          font-family: $Commissioner-Medium;
          @extend .Commissioner-Medium15;
          color: $text-clr-33;
        }

        .user_info {
          font-family: $Commissioner-Regular;
          @extend .Commissioner-Regular14;
          color: $text-clrE20;
        }
      }

      .team_details {
        display: flex;
        align-items: center;
        box-sizing: border-box;

        @include respond-to('small') {
          flex-wrap: wrap;
        }

        @include respond-to('medium') {
          padding: 0 0;
        }

        .team_icon_left {
          align-self: flex-start;
          max-width: 50%;

          img {
            width: 40px;
            height: 40px;
          }

          div {
            font-size: 1rem;
          }
        }
      }
    }
  }
}
