@import '../NextGen/Assets/sass/base/fonts';
@import '../NextGen/Assets/sass/base/global';
@import '../NextGen/Assets/sass/abstracts/colors';
@import '../NextGen/Assets/sass/abstracts/mixins';

.loading-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 2;
  cursor: not-allowed;
}

.search-wrapper {
  padding: 2rem;

  .search-asides__flex-row {
    display: block;
    flex-direction: row;

    @media(max-width: 991px) {
      display: block;
    }
  }

  input::-webkit-search-cancel-button {
    -webkit-appearance: none;
    background-repeat: no-repeat;
    padding: 2px;
    background-position: center center;
    background-size: 14px 14px;
    height: 11px;
    width: 11px;
    background-image: -webkit-gradient(linear, left top, right bottom, from(transparent), color-stop(0.46, transparent), color-stop(0.46, $text-clr70), color-stop(0.54, $text-clr70), color-stop(0.54, transparent), to(transparent)), -webkit-gradient(linear, right top, left bottom, from(transparent), color-stop(0.46, transparent), color-stop(0.46, $text-clr70), color-stop(0.54, $text-clr70), color-stop(0.54, transparent), to(transparent)) !important;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: middle;
    margin-right: 0.5em;

    &:hover {
      cursor: pointer;
    }
  }
}

.search-filters {
  width: 21%;
  float: left;

  @media(max-width: 991px) {
    width: 100%;
  }

  .search-facet-wrapper {
    @media(max-width: 991px) {
      display: block;
    }
  }
}

.search-results {
  width: 78%;
  float: right;
  margin-bottom: 10px;

  @media(max-width: 991px) {
    width: 100%;
    clear: both;
  }

  .MuiTypography-h6 {
    @media(max-width: 991px) {
      position: relative;
      top: 15px;
    }
  }
}

.aside-content__padding-top {
  padding-top: 1rem;

  @media(max-width: 991px) {
    width: 100%;
  }

  .MuiGrid-root {
    &.MuiGrid-container {
      flex-direction: column;

      .MuiPaper-root {
        max-width: 100%;
        width: 100%;
      }

      .MuiBox-root {
        @media(max-width: 991px) {
          padding: 16px 0;
        }

        .MuiCard-root {
          @media(max-width: 991px) {
            max-width: 310px;
            min-width: 310px;
          }
        }
      }
    }
  }
}

div.search-filter {
  display: flex;
  flex-direction: column;
  width: 100%;

  @media(max-width: 991px) {
    width: 100%;
  }
}

div.bulkButtonDiv {
  display: flex;
  justify-content: space-between;
}
