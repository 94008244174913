@import '../base/fonts';
@import '../base/global';
@import '../abstracts/colors';
@import '../abstracts/mixins';

.business-wrapper {
  width: 90%;
  max-width: 800px;
  border-radius: 5px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 99;
  padding: 20px 30px 20px 30px;
  background-color: $bg-white;

  @include respond-to('large') {
    top: 20px;
    transform: translate(-50%, 0%);
  }

  @include respond-to('ex-small') {
    width: 80%;
  }

  .group-name {
    font-family: $Commissioner-Medium;
    @extend .Commissioner-Medium21;
    color: $text-clr-BC;
  }

  .close_popup {
    position: absolute;
    right: 14px;
    top: 13px;
    color: $text-clr70;
    font-size: 19px;
    cursor: pointer;
    z-index: 99;
  }

  .MuiTabs-indicator {
    background-color: $text-clr-BC;
  }

  .content_team_wrapper {
    @extend .Commissioner-Medium16;
    color: $bg-Dune-back;
    font-family: $Commissioner-Medium;
    padding: 15px 0px 22px 0px;
    display: flex;
    justify-content: space-between;

    @include respond-to('ex-small') {
      display: block;
      padding: 10px 0px;
    }

    .team_wrapper_right {

      @include respond-to('ex-small') {
        margin-top: 15px;
      }
    }
  }

  .filter-raci {
    @extend .Commissioner-Medium21;
    color: $bg-Dune-back;
    font-family: $Commissioner-Medium;
    padding: 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include respond-to('ex-small') {
      display: block;
    }

    .role-filter-heading {
      @extend .Commissioner-Medium14;
      color: $text-clr-boulder-77;
      font-family: $Commissioner-Medium;
      margin-bottom: 6px;

      @include respond-to('ex-small') {
        padding-top: 10px;
      }
    }

    span {
      white-space: nowrap;
      margin-left: 5px;
      line-height: 1;
      border-radius: 9px;
      padding: 7px 10px 9px 10px;
      @extend .Commissioner-Medium14;
      font-family: $Commissioner-Medium;
      display: inline-block;
      cursor: pointer;

      &:first-child {
        margin-left: 0px;
      }

      &.default {
        color: $text-clr-33;
        background-color: $bg-Link-Water;
      }

      &.selected {
        background-color: $text-clr-BC;
        color: $text-clr-white;
      }
    }

    .search-box {
      width: 40%;

      @include respond-to('ex-small') {
        width: 100%;
      }

      input[type="search"] {
        font-family: $Commissioner-Regular;
        @extend .Commissioner-Regular14;
        color: $text-clr-boulder-77;
        width: 100%;
        padding: 10px;
        border: 1px solid transparent;
        border-bottom-color: $bg-Platinum-gray;

        &:focus-visible {
          outline: none;
          border: 1px solid transparent;
          border-bottom-color: $bg-Platinum-gray;
        }

        &::-webkit-search-cancel-button {
          position: relative;
          top: 1px;
          font-size: 16px;
          filter: grayscale(1) opacity(0.6);
        }
      }
    }
  }

  .results {
    color: $text-clr-mistblue-77;
  }

  .content_subheading {
    font-family: $Commissioner-Regular;
    @extend .Commissioner-Regular16;
    padding-top: 3px;
    color: $bg-gray-mist;
  }

  .tabs_wrapper {
    height: 250px;
    overflow: auto;
    align-items: flex-start;

    @include respond-to('ex-small') {
      height: 150px;
      overflow-x: hidden;
    }

    &::-webkit-scrollbar {
      width: 6px;
      background-color: $bg-clr-f5;
    }

    &::-webkit-scrollbar-thumb {
      background-color: $bg-clr-ED;
      height: 130px;
    }

    &::-webkit-scrollbar-track {
      background-color: $bg-white;
    }

    .tabs_section {
      padding: 0 0;
      list-style: none;
      display: flex;
      margin: 0 2% 0 0;

      &:nth-of-type(odd) {
        background-color: $bg-clr-FA;
      }

      .tabs_item {
        width: 30%;
        padding: 3%;
        box-sizing: border-box;
        color: $bg-gray-mist;
        font-family: $Commissioner-Regular;
        overflow-wrap: break-word;

        &:first-child {
          @include respond-to('small') {
            padding-right: 0;
          }
        }

        &.right_align {
          text-align: right;
        }

        @extend .Commissioner-Regular15;

        a {
          text-decoration: none;
          color: $text-clrE20;
          @extend .Commissioner-Medium14;
        }

        &:last-child {
          width: 10%;
        }

        .user_emailwrapper {
          div {
            font-family: $Commissioner-Regular;
            @extend .Commissioner-Regular14;
            color: $text-clrE20;
          }
        }
      }

      .team_details_right {
        margin-left: 7%;
        max-width: 73%;

        @include respond-to('ex-small') {
          margin-left: 5%;
          max-width: 45%;
        }

        .user_name {
          font-family: $Commissioner-Medium;
          @extend .Commissioner-Medium15;
          color: $text-clr-33;
          line-height: 1.3rem;
        }

        .user_info {
          font-family: $Commissioner-Regular;
          @extend .Commissioner-Regular15;
          color: $text-clr-mistblue-77;
        }
      }

      .team_details {
        @extend .flex-center;
        align-items: center;
        flex-wrap: nowrap;

        @include respond-to('medium') {
          padding: 0 0;
        }

        .team_icon_left {
          align-self: flex-start;
          max-width: 50%;

          img {
            width: 40px;
            height: 40px;
          }

          div {
            font-size: 1rem;
          }
        }
      }
    }
  }

  .btn_wrapper {
    display: flex;
    justify-content: space-between;
    padding: 15px 0px 0px 0px;

    &.top_button {
      justify-content: flex-end;
    }

    button {
      text-transform: none;
      white-space: nowrap;
      width: auto;
      font-family: $Commissioner-Medium;
      @extend .Commissioner-Medium15;
      border: 1px solid $text-clr-BC;
      padding: 13px 20px;
      min-height: unset;
      line-height: 1;
      border-radius: 4px;
      box-shadow: none;

      &:hover {
        box-shadow: none;
      }

      &.MuiButton-outlinedPrimary:not(.Mui-disabled) {
        border-color: $text-clr-BC;

        &:hover {
          background-color: $text-clr-white;
        }
      }

      &.MuiButton-contained.Mui-disabled {
        border-color: transparent;
        color: $text-clr-white;
        box-shadow: none;
        background-color: rgba(28, 117, 188, 0.5);
      }
    }

    .left_btn {
      button {
        &:first-child {
          margin-right: 10px;
        }
      }
    }

    .btn {
      padding: 5px 15px;
      @extend .Commissioner-Regular14;
      cursor: pointer;
      border-radius: 5px;
      margin: 0px;

      &:hover {
        opacity: 0.9;
      }
    }

    .btn_blue {
      background-color: $text-clr-BC;
      color: $text-clr-white;
      width: auto;
    }
  }

  .MuiTabs-flexContainer {
    border-bottom: 1px solid $bg-clr-E7;

    button {
      text-transform: capitalize;
      @extend .Commissioner-Regular16;
      color: $text-clrE20;
      font-family: $Commissioner-Regular;
    }

    .Mui-selected {
      color: $text-clr-BC;
      font-family: $Commissioner-Medium;
    }

    .MuiTab-root {
      width: auto;
    }
  }

  .MuiAppBar-colorDefault {
    background-color: $bg-white;
    box-shadow: none;
    padding-right: 16px;
  }

  .MuiBox-root-41 {
    padding: 0px 0px;
  }

  .MuiBox-root {
    padding: 0px 0px 20px 0px;
  }
}
