// STYLES FOR Insights **************/
$dark-grey: #2c2c2c;
$view-grey: #666666;
$tiles-grey: #e0e0e0;
$light-grey: #e3e3e3;
$dark-blue: #1f22ac;
$light-blue: #00a0df;

.content-insight {
  padding-bottom: 2em;

  ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .headerarea {
    h1 {
      font-size: 30px;
      padding: 10px 0;
      margin: 0;
    }

    nav {
      padding-top: 15px;

      ul.navigation {
        display: inline-block;

        li {
          display: flex;
          padding-right: 50px;
          float: left;

          span {
            text-decoration: none;
            font-size: 19px;
            color: $dark-grey;
            padding: 20px 0;
            border-bottom: 4px solid $dark-blue;
          }
        }
      }
    }
  }

  section.record-top {
    padding: 35px 0 40px;

    .section-title {
      font-size: 22px;
      font-weight: bold;
      padding-top: 8px;
      float: left;
    }

    .section-search {
      position: relative;
      height: 0;

      .searchbox {
        display: inline-table;
        position: absolute;
        left: 0;
        right: 0;

        input[type='text'] {
          border: 1px solid $dark-blue;
          border-radius: 3px 0 0 3px;
          padding: 5px 10px;
          width: 45vw;
          height: 25px;
          margin-top: 1px;
          font-size: 16px;

          &:focus {
            outline: none !important;
            border: 1px solid $dark-grey;
          }
        }

        button {
          background: $dark-blue;
          border-radius: 0 3px 3px 0;
          cursor: pointer;
          font-size: 16px;
          color: $light-grey;
          padding: 10px;
          border: none;
          margin: 0;

          &:disabled {
            background: $view-grey;
            cursor: default;
          }
        }

        ul.srcfilter {
          padding-top: 5px;
          text-align: center;

          li {
            font-size: 14px;
            display: inline-block;
            color: $view-grey;
            padding: 0 5px;
          }
        }
      }
    }

    .section-filtering {
      float: right;

      .dropdown-wrapper {
        width: auto;
        min-width: 180px;
      }
    }
  }

  .zero-insight-wrapper {
    text-align: center;
    padding: 2rem;
  }

  .record-block {
    border: 1px solid $light-grey;
    border-radius: 7px;
    padding: 30px 40px;
    margin-top: 10px;

    &:first-child {
      margin-top: 0;
    }

    span.denied {
      display: none;
    }

    &.access-denied {
      position: relative;
      overflow: hidden;
      color: $view-grey;

      .recordtitle,
      .recordbody {
        opacity: 0.2
      }

      &:after {
        position: absolute;
        background: rgba(0, 0, 0, 0.3);
        content: "";
        display: block;
        z-index: 100;
        height: 100%;
        width: 100%;
        left: 0;
        top: 0;
      }

      span.denied {
        color: $light-grey;
        transform: translate(-50%);
        position: absolute;
        font-weight: bold;
        font-size: 42px;
        display: block;
        z-index: 101;
        left: 50%;
        top: 40%;
      }

      .recordtitle {
        .recordinfo {
          .view {
            background: $view-grey;
          }
        }
      }

      .recordimage {
        filter: grayscale(100%);
      }
    }

    .recordtitle {
      .recordimage {
        float: left;
        max-width: 250px;
      }

      .recordinfo {
        color: $view-grey;
        font-size: 16px;
        float: right;
        display: flex;

        em {
          padding: 0 5px;
        }

        span {
          white-space: nowrap;
        }

        &.withlogo {
          .recordname {
            max-width: 400px;
          }
        }

        .recordStarRating {
          margin-top: -4px;
          white-space: nowrap;
        }

        .view {
          border-radius: 3px;
          font-size: 17px;
          text-transform: uppercase;
          text-decoration: none;
          background: $dark-blue;
          padding: 17px 35px;
          margin: 0 0 0 35px;
          cursor: pointer;
          color: $light-grey;
          border: none;
          max-height: 54px;

          &:hover {
            background: $light-blue;
          }
        }
      }
    }

    .recordbody {
      padding-top: 30px;
      display: flex;
      clear: both;

      .recordtiles {
        border: 1px solid $tiles-grey;
        border-radius: 3px;
        padding: 20px 5px;
        margin-left: 10px;
        float: left;
        width: 20%;

        &:first-child {
          margin-left: 0;
        }

        &:nth-child(2) {
          width: 40%;
        }

        h3 {
          margin: 0;
          padding: 0 0 25px 30px;
          color: $dark-grey;
          font-size: 16px;
        }

        ul.vchart {
          li {
            border-left: 1px solid $tiles-grey;
            box-sizing: border-box;
            position: relative;
            text-align: center;
            font-size: 13px;
            color: $view-grey;
            float: left;
            width: 50%;

            &:first-child {
              border: none;
            }

            span {
              display: block;
              font-size: 30px;
              font-weight: bold;
              color: $dark-grey;
              padding-top: 5px;

              &.vdate {
                font-size: 14px;
                padding-top: 10px;
              }
            }

            .totalCount {
              text-decoration: none;
              color: $light-blue;

              &:hover {
                text-decoration: underline;
                color: $dark-grey;
              }
            }
          }
        }

        &.list02 {
          ul.vchart {
            li {
              width: 25%;
            }
          }
        }
      }
    }
  }
}
