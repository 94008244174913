#root {
  min-height: 100vh;
}

.errorLoading {
  margin: auto;
}

.errorLoading a {
  background-color: transparent !important;
}

.hidden {
  display: none !important;
}

.content-wrapper.loader {
  min-height: calc(100vh - 218px);
}

.block {
  display: block !important;
}

div,
span,
p,
label,
h1,
h2,
h3,
h4,
h5,
h6 {
  word-break: break-word;
}

iframe {
  border: 0;
}

body {
  font-family: Karbon, sans-serif;
  margin: 0px;
  box-sizing: border-box;
  background: linear-gradient(270deg, #f3f3f4, #cccccc, #94939d, #6b6a77);
  background-size: 800% 800%;
  -webkit-animation: AnimationName 30s ease infinite;
  -moz-animation: AnimationName 30s ease infinite;
  animation: AnimationName 30s ease infinite;
}

@-webkit-keyframes AnimationName {
  0% {
    background-position: 0% 50%
  }

  50% {
    background-position: 100% 50%
  }

  100% {
    background-position: 0% 50%
  }
}

@-moz-keyframes AnimationName {
  0% {
    background-position: 0% 50%
  }

  50% {
    background-position: 100% 50%
  }

  100% {
    background-position: 0% 50%
  }
}

@keyframes AnimationName {
  0% {
    background-position: 0% 50%
  }

  50% {
    background-position: 100% 50%
  }

  100% {
    background-position: 0% 50%
  }
}

@font-face {
  font-family: 'Karbon-Regular';
  src: "../../fonts/Karbon-Regular.otf" format('embedded-opentype');
  font-weight: normal;
  font-style: normal;
}
