@import "../../../NextGen/Assets/sass/base/fonts";
@import "../../../NextGen/Assets/sass/base/global";
@import "../../../NextGen/Assets/sass/abstracts/colors";
@import "../../../NextGen/Assets/sass/abstracts/mixins";

.error-page-title {
  h1 {
    font-size: 1.75rem;
    font-family: $Karbon-Medium;
    color: $text-clrE20;
  }
}

.error-page-bg {
  background-color: $bg-white;

  .header-wrapper {
    padding-top: 1rem;
    margin: auto;
    width: 94%;
    max-width: 1366px;
  }

  .result-wrapper {
    justify-content: center;
    padding: 0 1rem 1rem 1rem;

    .table-wrapper {
      width: 70%;
      margin: auto;
    }
  }
}

.error-page-messages {
  margin-bottom: 1rem;
  border-bottom: solid $text-clr-33 1px;

  p {
    color: $text-clrE20;
    font-family: $Commissioner-Regular;
    padding-bottom: 1rem;
  }

}

.back-button__clickable {
  cursor: pointer;

  p {
    color: $text-clrE20;
    font-family: $Commissioner-Regular;
    margin: 0;
  }
}

.link-wrapper {
  .group-link {
    font-family: $Commissioner-Regular;
    @extend .Commissioner-Regular14;
    color: $text-clr-BC;
    text-decoration: none;

    &.bold {
      font-family: $Commissioner-Bold
    }
  }

  .link-title {
    @extend .Commissioner-SemiBold;
    color: $heading-Dune-black-33;
    font-family: $Commissioner-SemiBold;
    text-decoration: none;
    margin-bottom: auto;
  }
}
