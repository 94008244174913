@import "../../Assets/sass/base/fonts";
@import "../../Assets/sass/base/global";
@import "../../Assets/sass/abstracts/colors";
@import "../../Assets/sass/abstracts/mixins";

.manageapp_page_wrapper {
  .manageapp_page_wrapper_section {
    @extend .max_width;
    padding: 20px 0px;
    width: 94%;
    max-width: 1366px;

    .manageapp_left_section {
      &.col-3 {
        display: block;
        flex: 0 0 21%;
        margin-left: 0;
        margin-right: 20px;
        width: 21%;

        @include respond-to("medium") {
          width: 100%;
        }
      }

      .manageapp_menu_wrapper {
        background-color: $bg-white;
        border-radius: 4px;

        .manageapp_menu {
          padding: 26px 25px 30px;

          .MuiListItem-root {
            padding: 8px 15px 10px;
            border-radius: 3px;

            a {
              color: $text-clr-mistblue-77;
              text-decoration: none;
            }

            &.active-menu {
              background-color: $bg-White-SmokeF7;

              span {
                a {
                  color: $text-clr-33;
                  font-weight: 600;
                }
              }
            }

            .MuiListItemText-root {
              margin: 0;

              .MuiListItemText-primary {
                font-family: $Commissioner-Medium;
                @extend .Commissioner-Medium15;
                color: $text-clr-mistblue-77;
              }
            }

            &.active {
              background-color: $bg-White-SmokeF7;

              .MuiListItemText-primary {
                color: $text-clr-33;
                font-weight: 600;
              }
            }
          }

          @include respond-to("small") {
            padding: 16px 15px 20px;
          }
        }
      }
    }

    .manageapp_right_section {
      width: 78%;
      float: right;
      margin-bottom: 10px;

      @include respond-to("medium") {
        width: 100%;
        clear: both;
      }

      .manageapp_section_wrapper {
        padding: 30px;
        background-color: $text-clr-white;
        border-radius: 4px;

        .dialog-modal.app-forms>div {
          padding: 0;
        }

        .manageapp_section_heading {
          font-family: $Commissioner-Medium;
          @extend .Commissioner-Medium21;
          color: $text-clr-33;

          span {
            font-family: $Commissioner-SemiBold;
            @extend .Commissioner-SemiBold;
            font-size: 1.312rem;
            font-weight: 600;
          }
        }

        .manageapp_section_sub {
          p {
            font-family: $Commissioner-Regular;
            @extend .Commissioner-Medium14;
            color: $text-clr-33;
            margin-bottom: 5px;

            span {
              font-family: $Commissioner-SemiBold;
              @extend .Commissioner-SemiBold;
            }
          }
        }

        .btn-wrapper {
          display: flex;
          margin-top: 30px;
          justify-content: end;

          @include respond-to('small') {
            margin-top: 30px;
          }

          button {
            padding: 13px 20px;
            min-height: unset;
            line-height: 1;
            font-family: $Karbon-Medium;
            border: 1px solid $text-clr-BC;
            border-radius: 4px;
            text-transform: capitalize;
            @extend .Karbon-Medium18;
            white-space: nowrap;
            box-shadow: none;

            &:hover {
              box-shadow: none;
            }

            @include respond-to('small') {
              padding: 9px 16px;
              width: unset;
            }
          }

          .MuiButton-outlinedPrimary,
          .MuiButton-outlinedPrimary:hover {
            color: $text-clr-BC;
            background-color: $text-clr-white;
          }

          .MuiButton-contained.Mui-disabled {
            border-color: transparent;
          }
        }
      }
    }

    .manageapp_access_wrapper {
      background-color: $bg-white;
      border-radius: 5px;
      padding: 30px 30px 40px;
      text-align: center;

      .message {
        margin-bottom: 20px;
        font-family: $Commissioner-Regular;
        @extend .Commissioner-Regular16;
        color: $text-clr-33;
      }

      .message_link {
        font-family: $Commissioner-Medium;
        @extend .Commissioner-Medium16;
        color: $text-clr-BC;
        text-decoration: none;
      }
    }
  }
}
