.modal-wrapper {
  padding: 3px 3px 20px 3px;
  position: relative;

  .close-modal {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;

    .cancel-icon {
      color: #f50057;

      &:hover {
        color: #b60744;
      }
    }
  }

  .modal-content {
    clear: both;
    padding: 80px 15px 30px !important;

    .error__center {
      text-align: center;
      color: red;
      font-size: 1rem;
      margin-bottom: 1rem;
    }

    .response-message {
      font-size: 1.3rem;
      color: #08a408;
    }
  }
}

.loading-wrapper__center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.formControl-wrapper {
  .group-footnote {
    font-style: italic;
    font-size: 0.8rem;
  }
}