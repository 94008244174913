.text-align-left {
  text-align: left;
}
.text-align-center {
  text-align: center;
}
.text-align-right {
  text-align: right;
}
.text-align-justify {
  text-align: justify;
}
.align-left{
  float: left;
}
.align-right {
  float: right;
}
.align-center {
  display: flex;
  justify-content: center;
}
.field {
  .file-link, .file-icon {
    font-weight: normal;
    font-size: 1rem;
  }
  .file-icon {
    padding-right: 2px;
    span {
      background-image: url('../../images/file_icon.png');
      background-repeat: no-repeat;
      display: inline-block;
      width: 20px;
      height: 20px;
      vertical-align: sub;
    }
  }
}
