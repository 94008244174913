@import 'grid';
@import '../abstracts/colors';
@import 'fonts';
@import '../abstracts/mixins';

/*------------------------------Extends class-----------------------*/
html {
  font-size: 16px;

  @include respond-to('small') {
    font-size: 10px;
  }
}

body {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: $Commissioner-Regular;

  * {

    &::-webkit-scrollbar {
      background-color: $bg-White-SmokeF7;
      width: 15px;
    }

    &::-webkit-scrollbar-track {
      background-color: $bg-White-SmokeF7;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 20px;
      border: 4px solid $bg-White-SmokeF7;
      background-color: $bg-clr-d6;
    }
  }
}

.max_width {
  width: 90%;
  margin: auto;
}

.max_width1366 {
  max-width: 1366px;
  margin: auto;
}

.min_content_height {
  min-height: calc(100vh - 152px);

  &.full {
    min-height: 100vh;
  }
}

.uppercase {
  text-transform: uppercase
}

.navbar-wrapper,
.home_intelligence-wrapper,
.home-featured-outer-wrapper,
.home_application_wrapper,
.footer-outer-wrapper {
  ul {
    display: flex;
    align-items: center;

    li {
      list-style-type: none;
    }
  }

  dl,
  ol,
  ul {
    margin: 0 0 0 0;
    padding: 0 0 0 0;

  }
}

.Commissioner-Regular10 {
  font-size: 0.625rem;
  letter-spacing: 0rem;
  line-height: 2rem;
}

.Commissioner-Regular16 {
  font-size: 1rem;
  letter-spacing: 0rem;
  line-height: 1.437rem;
}

.Commissioner-Regular15 {
  font-size: 0.937rem;
  letter-spacing: 0rem;
  line-height: 1.437rem;
}

.Commissioner-Medium16 {
  font-size: 1rem;
  letter-spacing: 0px;
  line-height: 1.437rem;
}

.Commissioner-Medium21 {
  font-size: 1.312rem;
  letter-spacing: 0px;
  line-height: 1.562rem;
}

.Karbon-Regular22 {
  font-size: 1.375rem;
  letter-spacing: -0.006rem;
  line-height: 1.875rem;
}

.Karbon-Regular25 {
  font-size: 1.562rem;
  letter-spacing: 0rem;
  line-height: 1.437rem;
}

.Karbon-Regular26 {
  font-size: 1.625rem;
  letter-spacing: 0rem;
  line-height: 1.625rem;
}

.Karbon-samibold29 {
  font-size: 1.812rem;
  letter-spacing: -.006rem;
  line-height: 2.562rem;
}

.Karbon-Semibold51 {
  font-size: 3.187rem;
  letter-spacing: -.0312rem;
  line-height: 4.437rem;
}

.Commissioner-Medium15 {
  font-size: 0.937rem;
  letter-spacing: 0rem;
  line-height: 1.437rem;
}

.Commissioner-Medium13 {
  font-size: 0.812rem;
  letter-spacing: 0rem;
  line-height: 1.437em;
}

.Commissioner-Medium14 {
  font-size: 0.875rem;
  letter-spacing: 0rem;
  line-height: 1.437em;
}

.Commissioner-SemiBold {
  font-size: 1rem;
  letter-spacing: -0.062rem;
  line-height: 1.187rem;
}

.Commissioner-Bold {
  font-size: 0.562rem;
  letter-spacing: 0rem;
  line-height: 2rem;
}

.Karbon-Medium17 {
  font-size: 1.062rem;
  letter-spacing: 0rem;
  line-height: 1.312rem;
}

.Karbon-Medium18 {
  font-size: 1.125rem;
  letter-spacing: 0rem;
  line-height: 1.625rem;
}

.Commissioner-Regular14 {
  font-size: 0.875rem;
  letter-spacing: 0rem;
  line-height: 1.437rem;
}

.Commissioner-Regular17 {
  font-size: 1.062rem;
  letter-spacing: 0rem;
  line-height: 1.437rem;
}

.Karbon-Semibold23 {
  font-size: 1.437rem;
  letter-spacing: 0rem;
  line-height: 2rem;
}

.Karbon-Semibold29 {
  font-size: 1.812rem;
  letter-spacing: -0.062rem;
  line-height: 2.562rem;
}

.Karbon-Semibold25 {
  font-size: 1.562rem;
  letter-spacing: 0rem;
  line-height: 1.437rem;
}

/*-----------------------bg class--------------*/
.cat-section {
  border-radius: 3px;
  color: $text-clr-white;
  position: absolute;
  right: 10px;
  top: 10px;
  background-color: $bg-Silver-Chalice;
  padding: 5px 10px;
  font-family: $Commissioner-Bold;
  font-size: 9px;
}

.bg-purple {
  background-color: $bg-Eminence-purple;
}

.bg-yellow {
  background-color: $bg-Fuel-Yellow;
}

.bg-Bittersweet {
  background-color: $bg-Bittersweet;
}

.bg-blue {
  background-color: $bg-French-Blue;
}

.karbon-heading-w29 {
  font-family: $Karbon-Regular;
  font-size: 29px;
  line-height: 41px;
  font-weight: 600;
}

.bg-f7 {
  background-color: $bg-White-SmokeF7;
}

/*--------------class for the show element-----*/
.display_block {
  display: block;
}

/*-------------------input-------------------*/
.input-box {
  background-color: $bg-white;
  border-radius: 5px;
  font-size: 16px;
  font-family: $Commissioner-Light;
  color: $placeholder-text-clr-ac;
  outline-offset: 0px;
  border: none;
}

.input-box:focus {
  outline: none;
}

.input-btnsearch {
  background-color: $bg-Dark-blue;
  width: 148px;
  height: 48px;
  text-align: center;
  border: 0;
  outline: 0px;
  display: inline-block;
}

/*------------------border------*/
.border_tb {
  border: 1px solid $bg-Link-Water;
  border-left: 0px;
  border-right: 0px;
}

/*------------------------margin/ padding------------*/

/*-----------------Flex box---------*/
.flex-center {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

.row {
  @extend .flex-center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
}

.editor-button-magenta {
  color: #C800A1;
  border: 1px solid rgba(200, 0, 161, 0.5);
  background: #fff;
  cursor: pointer;
  outline: none;
  padding: 8px 15px;
  box-sizing: border-box;
  font-size: 0.875rem;
  min-width: 64px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 500;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-decoration: none;

  &:hover {
    background-color: rgba(200, 0, 161, 0.04);
    border: 1px solid #C800A1;
  }
}

/*-------------Button----------*/
.join {

  button,
  button:hover {
    margin: 0;
    background: $text-clr-white;
    @extend .Commissioner-Regular15;
    padding: 10px 20px;
    font-family: $Commissioner-Medium;
    width: 100%;
    text-transform: none;
  }

  .requesting.Mui-disabled {
    background-color: $text-clr-white;
    color: $text-clr7B;
  }

  .request-failed.Mui-disabled {
    color: $bg-clr-red;
  }

  .request-sent.Mui-disabled {
    color: $bg-green400;
    background-color: $bg-clr-f5;
    border-color: transparent;
  }
}

.green_clr {
  filter: invert(27%) sepia(100%) saturate(727%) hue-rotate(83deg) brightness(103%)
}
