@import '../Assets/sass/base/fonts';
@import '../Assets/sass/base/global';
@import '../Assets/sass/abstracts/colors';
@import '../Assets/sass/abstracts/mixins';

.group_page_wrapper {
  background-color: $bg-White-SmokeF7;

  .group_page_section {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    .group_heading_wrapper {
      padding: 7px 0 14px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @include respond-to('small') {
        flex-wrap: wrap;
      }
    }

    .col-12 {
      margin: 0px;
    }

    .message {
      margin: 0px auto;
      padding: 46px 0;
      justify-content: center;
      display: flex;
    }

    .col-9 {
      flex: 0 0 72.18%;
      width: 72.18%;
      margin-right: 0px;
      margin-left: 0px;

      .col-4 {
        flex: 0 0 32.17%;
        width: 32.17%;
        margin-left: 0px;
        min-height: 125px;
        margin-right: 1.7%;

        &:nth-child(3n) {
          margin-right: 0%;
        }
      }

      @include respond-to('medium') {
        flex: 0 0 100%;
        width: 100%;
        margin: auto;
      }
    }

    .col-3 {
      display: block;
      flex: 0 0 23.8%;
      width: 23.8%;
      margin-right: 0px;

      @include respond-to('medium') {
        display: flex;
        flex: 0 0 98%;
        width: 98%;
        justify-content: space-between;
        margin: auto;
        flex-wrap: wrap;
      }

      @include respond-to('ex-small') {
        flex: 0 0 100%;
        width: 100%;
      }
    }

    .group_wrapper {
      padding-top: 0px;
    }
  }

  .group_wrapper_section {
    @extend .max_width;
    padding: 15px 0px;
    width: 94%;
    max-width: 1366px;

    .group_innersection {
      background-color: $bg-white;
      padding: 25px 20px;
    }

    .group_heading {
      color: $text-clr-33;
      font-family: $Commissioner-SemiBold;
      @extend .Commissioner-Regular14;
      font-size: 1.25rem;
      padding-bottom: 20px;
    }

    .filter_wrapper {
      display: flex;
      align-items: center;
      color: $text-clr-boulder-77;
      font-family: $Commissioner-SemiBold;
      @extend .Commissioner-Medium14;

      @include respond-to('small') {
        flex-wrap: wrap;
      }

      .sort_by_section {
        margin-right: 15px;
        position: relative;
        display: flex;
        align-items: center;

        .selected_value {
          color: $text-clr-boulder-77;
          font-family: $Commissioner-SemiBold;
          @extend .Commissioner-Medium14;
          border: none;
          background-color: transparent;
          margin-left: 8px;
          outline: none;
          display: flex;
          cursor: pointer;
          align-items: center;
        }
      }

      .filter_section {
        display: flex;
        margin-right: 15px;

        .filter_by {
          color: $bg-gray-Boulder;
          font-family: $Commissioner-Medium;
          @extend .Commissioner-Medium14;
          display: flex;
          align-items: center;
          cursor: pointer;

          .selected_item {
            font-family: $Commissioner-SemiBold;
            text-transform: capitalize;
            padding-left: 5px;
            display: flex;
            align-items: center;
          }
        }
      }
    }

    .group_search {
      cursor: pointer;
      color: $bg-gray-Boulder;

      input {
        border: none;
        background: transparent;
        outline: none;

        &:focus {
          outline: none;
        }

        &::placeholder {
          font-family: $Commissioner-Regular;
          @extend .Commissioner-Regular14;
          color: $text-clr-boulder-77;
        }
      }

      .group_inner_search {
        display: flex;
        align-items: center;
        border: solid 1px $bg-clr-99;
        padding: 10px 15px;
        border-radius: 3px;

        @include respond-to('small') {
          padding: 5px;
        }

        .MuiSvgIcon-root {
          font-size: 18px;
          margin-right: 10px;
          color: $text-clr-mistblue-77;

          @include respond-to('small') {
            font-size: 14px;
            margin-right: 5px;
          }
        }
      }
    }

    .group_pageleft {
      padding: 0px;
      display: flex;
      flex-direction: column;

      .col-4 {
        @include respond-to('large') {
          flex: 0 0 31.8%;
          width: 31.8%;
          margin-right: 2.3%;
        }

        @include respond-to('medium') {
          width: 49%;
          flex: 0 0 49%;
          margin: 0 2% 20px 0%;

          &:nth-child(even) {
            margin-right: 0px;
          }

          &:nth-child(odd) {
            margin-right: 2%;
          }
        }

        @include respond-to('exx-small') {
          width: 100%;
          flex: 0 0 100%;

          &:nth-child(odd) {
            margin-right: 0%;
          }
        }
      }

      a {
        background-color: $bg-white;
        width: 100%;
        box-sizing: border-box;
        text-decoration: none;
      }
    }

    .group_innerheading {
      font-family: $Karbon-Medium;
      @extend .Karbon-Medium18;
      padding-bottom: 30px;
      color: $text-clrE20;

      &.with_just_added {
        width: 65%;
      }

      .just-added {
        top: 25px;
        right: 20px;
      }
    }

    .data_count-wrapper {
      display: flex;
      color: $text-clr-boulder-77;
      font-family: $Karbon-Medium;
      @extend .Karbon-Medium17;
      justify-content: space-between;
      line-height: 0px;
      position: absolute;
      bottom: 20px;
      left: 20px;
      right: 20px;

      .data_count_innerleft {
        display: flex;
        align-items: center;
        color: $text-clr7B;
        font-family: $Commissioner-Medium;
        font-size: 0.875rem;

        .data_count {
          margin-right: 20px;
          display: flex;
          align-items: center;

          img {
            margin-right: 8px;
            width: 14px;
          }
        }

        .data_statics {
          display: flex;
          align-items: center;

          img {
            margin-right: 8px;
            width: 14px;
          }
        }
      }
    }

    .just-added {
      font-family: $Commissioner-SemiBold;
      position: absolute;
      top: 15px;
      right: 15px;
      color: $text-clr-white;
      background-color: $text-clr-BC;
      border-radius: 9px;
      font-size: 12px;
      padding: 3px 8px 5px 8px;
      line-height: 14px;
      z-index: 1;

      @include respond-to('large') {
        font-size: 9.5px;
      }
    }
  }
}
