/*-----------------------Heading color-------------*/
$heading-Dune-black-33: #333333;
$heading-blk-3B: #33373B;

/*----------------------Text color----------------------*/
$text-clr-white: #FFFFFF;
$text-clr-mistblue-77: #6B6A77;
$text-clr-boulder-77: #777777;
$text-clr-33: #333333;
$text-clr-BC: #1C75BC;
$text-clrD6: #D0CFD6;
$text-clrE20: #1C1E20;
$text-clr8cc: #C3C8CC;
$text-clr7B: #7B7B7B;
$text-clr70: #707070;
$text-clrC1: #C11717;
$text-clr0E: #0E7B11;
$text-B0F-clr: #0C7B0F;
$text-22-clr: #DC4822;
$text-clr-99: #999999;
$text-clr91: #191919;
/*-------------search-box--------------------*/
$placeholder-text-clr-ac: #ACACAC;
$bdr-clr-E8: #E8E8E8;
$bdr-clr-c1c1: #C1C1C1;
/*------------------bg color------------*/
$bg-clr-E7: #E7EBED;
$bg-clr-FA: #FAFAFA;
$bg-clr-ED: #EDEDED;
$bg-clr-E1: #E1E5E6;
$bg-green400: #0FC400;
$bg-Bittersweet: #FA7860;
$bg-French-Blue: #0075BD;
$bg-White-SmokeF7: #F4F6F7;
$bg-white: #FFFFFF;
$bg-Dune-back: #333333;
$bg-Silver-Chalice: #ACACAC;
$bg-Fuel-Yellow: #F4AC24;
$bg-Eminence-purple: #7F2E7B;
$bg-Dark-blue: #00467E;
$bg-gray-mist: #6B6A77;
$bg-gray-Boulder: #777777;
$bg-Shocking-Orange: #EF5B40;
$bg-Charcoal-black: #33373B;
$bg-gray-Gainsboro: #DBDBDB;
$bf-gray-Seashell: #F0F0F0;
$bg-White-SmokeF5: #F4F5F5;
$bg-orange-Bittersweet: #FA7860;
$bg-Link-Water: #D8E5F0;
$bg-gray-Mountain-Mist: #94939D;
$bg-Aqua-HazeF4: #F3F3F4;
$bg-Pastel-Grey: #CCCCCC;
$bg-blue-Rhino: #2E3E5C;
$bg-Platinum-gray: #E2E2E2;
$bg-clr-red: #c8102e;
$bg-clr-f5: #F5F5F5;
$bg-clr-DE: #DEDEDE;
$bg-clr-99: #999999;
$bg-clr-8A: #8A8A8A;
$bg-clr-52: #002352;
$bg-clr-d6: #D6DADD;
$bg-clr-FC: #FAFBFC;
$bg-clr-4E5: #E2E4E5;
$bg-success: #EAF7EA;
$bg-error: #F7E9E5;
$bg-black: #000000;
