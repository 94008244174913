.content-home {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  padding-top: 2em;
  padding-left: 2em;
  padding-right: 2em;
  font-size: 1vw;

  .loc-title {
    padding-right: 1em;
    font-size: 2rem;
    display: flex;
  }
  .content-home-wrapper {
    display: flex;
    flex-direction: row;
  }
  .content-company-name{
    display: flex;
    flex-direction: row;
  }
}

.content-image {
  width: 5%;
  height: 5%;
  padding-right: 1em;
}
