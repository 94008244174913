.hide-pagination {
  display: none;
}

.MuiTablePagination-root {
  .MuiTablePagination-spacer {
    display: none;
  }

  .MuiTablePagination-toolbar {
    justify-content: center;
  }

  .MuiTablePagination-selectRoot {
    margin-right: 8px
  }
}
