div.cart-background{
  width: 80%;
  margin: auto;
  margin-top: 16px;
  padding-bottom: 16px;
  min-height: 600px;
}
div.floating-btn-group{
  text-align: center;
  min-width: 350px;
  position: fixed;
  bottom: 0px;
  right: 0px;
}
div.section-header{
  text-align: center;
  margin: auto;
}
div.no-content{
  text-align: center;
  margin: auto;
  margin-top: 50px;
  padding: 1em;
}
h4.cart-title{
  padding: 24px;
}

@media only screen and (max-width: 600px) {
  div.cart-background{
    width: 90%;
  }
  div.floating-btn-group{
    width: 100%;
    z-index: 1;
  }
}

.error__center {
  text-align: center;
  color: red;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.loading-wrapper__center {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
}

.loading-wrapper__center.inprogress {
  position: fixed;
  width: 80%;
  z-index: 100;
}
.loader-wrapper-eventprogress {
  background: #fff;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  padding: 15px;
  border-radius: 50%;
}
.MuiPaper-root {
	position: relative;

	.success-email {
		font-size: 1rem;
    color: #08a408;
    position: relative;
		top: 5px;
	}

	.sending-email {
		font-size: 1rem;
    position: relative;
		top: 5px;
	}

	.request-failed {
		font-size: 1rem;
    position: relative;
		top: 5px;
		color: #f50057;
	}
}

