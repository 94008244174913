@import '../Assets/sass/base/fonts';
@import '../Assets/sass/base/global';
@import '../Assets/sass/abstracts/colors';
@import '../Assets/sass/abstracts/mixins';

.error_details_wrapper {
  @extend .max_width;
  padding: 20px 0px;
  width: 94%;
  max-width: 1366px;

  .error_item {
    padding: 30px;
    margin-top: 10px;
    background-color: $text-clr-white;
    border-radius: 4px;
    text-align: center;
  }
}

.revision_history_page_wrapper {
  background-color: $bg-White-SmokeF7;

  .revision_history_page_wrapper_section {
    @extend .max_width;
    padding: 20px 0px;
    width: 94%;
    max-width: 1366px;

    .revision_history_wrapper {
      background-color: $bg-white;
      border-radius: 5px;
      padding: 30px;

      @include respond-to('small') {
        padding: 15px;
      }

      .revision_history_header {
        .sub_heading {
          font-family: $Commissioner-Regular;
          @extend .Commissioner-Regular17;
          color: $text-clr-33;
        }

        .previous_list {
          padding-top: 25px;
          display: inline-flex;
          align-items: center;
          color: $text-clr-mistblue-77;
          font-family: $Commissioner-Regular;
          font-size: 18px;
          text-decoration: none;
          cursor: pointer;

          svg {
            font-size: 20px;
            color: $text-clr-mistblue-77;
            margin-right: 5px;
          }

          @include respond-to('small') {
            font-size: 12px;

            svg {
              font-size: 15px;
              margin-right: 5px;
            }
          }
        }
      }

      .revision_history_table_wrapper {
        padding: 30px 0;

        .MuiTableContainer-root {
          box-shadow: none;
          color: $text-clr-33;

          table {
            th {
              font-family: $Commissioner-Medium;
              @extend .Commissioner-Medium15;
              color: $text-clr-33;
              padding: 0 15px 15px 0;

              @include respond-to('small') {
                padding: 0 10px 10px 0;
              }
            }

            td {
              font-family: $Commissioner-Regular;
              @extend .Commissioner-Regular15;
              color: $text-clr-33;
              padding: 8px 15px 8px 0;

              .date {
                text-decoration: none;
                color: $text-clr-BC;
              }

              .restore {
                font-family: $Commissioner-Regular;
                @extend .Commissioner-Regular15;
                color: $text-clr-BC;
                text-transform: capitalize;
                padding-left: 15px;
                padding-right: 15px;
                cursor: pointer;

                &:hover {
                  background-color: transparent;
                }

                &.Mui-disabled {
                  color: $text-clr-99;
                }
              }

              .MuiChip-root {
                border-radius: 3px;
                background-color: $text-B0F-clr;
                border-color: $text-B0F-clr;
                padding: 12px 8px;

                .MuiChip-label {
                  font-family: $Commissioner-Medium;
                  @extend .Commissioner-Medium15;
                  color: $text-clr-white;
                  padding: 0;

                  @include respond-to('small') {
                    font-size: 8px;
                  }
                }
              }
            }
          }
        }
      }

      .compare_revision_button {
        padding: 40px 0 30px;

        button {
          padding: 13px 24px;
          min-height: unset;
          line-height: 1;
          font-family: $Commissioner-Medium;
          @extend .Commissioner-Medium15;
          text-transform: capitalize;
          border: 1px solid $text-clr-BC;
          border-radius: 4px;
          white-space: nowrap;
          box-shadow: none;

          &:hover {
            box-shadow: none;
          }

          @include respond-to('small') {
            padding: 9px 16px;
            width: unset;
          }
        }

        .MuiButton-containedPrimary,
        .MuiButton-containedPrimary:hover {
          color: $text-clr-white;
          background-color: $text-clr-BC;
        }

        .MuiButton-contained.Mui-disabled {
          border-color: $text-clr-99;
          color: $text-clr-white;
          background-color: $text-clr-99;
        }
      }

      // Revision History Full View
      .revision_history_view_wrapper {
        .revision_subject_wrapper {
          padding: 30px 0 0 0;
          border-bottom: 1px solid $bg-Platinum-gray;

          .subject_title {
            font-family: $Commissioner-SemiBold;
            @extend .Commissioner-SemiBold;
            color: $text-clr-BC;
            font-size: 21px;
            margin-bottom: 10px;

            @include respond-to('small') {
              font-size: 15px;
            }
          }

          .subject_subtitle {
            font-family: $Commissioner-Medium;
            font-size: 18px;
            color: $text-clr-33;
            display: flex;
            justify-content: space-between;

            @include respond-to('small') {
              font-size: 12px;
            }

            .revision_prev_next {
              span {
                color: $text-clr-BC;
                text-decoration: none;
                padding: 0 5px;
                cursor: pointer;

                &:nth-child(2) {
                  border-left: 2px solid $text-clr-99;
                }
              }
            }
          }

          .revision_comparison {
            margin-top: 30px;

            .comparing_column_heading {
              display: flex;
              flex-direction: row;

              .comparing_rev {
                width: 50%;
                font-family: $Commissioner-Medium;
                font-size: 18px;
                margin-bottom: 10px;
                padding-right: 20px;
                display: flex;
                flex-wrap: wrap;

                a {
                  text-decoration: none;
                  color: $text-clr-BC;
                  padding-right: 4px
                }

                .user_info {
                  padding-right: 5px;
                }

                .restore_ver {
                  border-left: 2px solid $text-clr-99;
                  padding: 0 0 0 5px;
                  color: $text-clr-BC;
                  cursor: pointer;
                }

                .current_ver {
                  color: $text-clr-99;
                  cursor: none;
                }

                @include respond-to('small') {
                  font-size: 12px;
                }
              }
            }
          }
        }

        .revision_body_wrapper {
          margin-top: 10px;

          .content {
            padding: 10px 0;
            color: $text-clr-33;
          }
        }

        .revision_footer_wrapper {
          .title {
            margin-bottom: 10px;
          }

          a {
            color: $text-clr-BC;
            text-decoration: none;
            white-space: normal;
          }

          @media (max-width: 900px) {

            .title,
            .info {
              flex: 1 1 50%;
            }
          }

          .MuiGrid-container {
            margin-left: 0;

            .MuiGrid-item {
              padding-left: 0;
              padding-right: 15px;
              white-space: normal;
              display: flex;
              flex-direction: column;
              margin-top: 20px;
            }
          }
        }

        .revision_restore_button {
          margin-top: 60px;

          .restore_ver {
            border-radius: 4px;
            background-color: $text-clr-BC;
            padding: 14px 20px;
            @extend .Karbon-Medium18;
            font-family: $Karbon-Medium;
            color: $text-clr-white;
            cursor: pointer;
            display: inline-block;
            line-height: 1;
          }
        }

        .title {
          margin-bottom: 15px;
          font-family: $Commissioner-Medium;
          font-size: 18px;

          @include respond-to('small') {
            margin-bottom: 10px;
            font-size: 12px;
          }
        }

        .info {
          font-family: $Commissioner-Regular;
          font-size: 18px;

          @include respond-to('small') {
            font-size: 12px;
          }

          * {
            margin: 0;
          }
        }

        .revision_changes_wrapper {

          .revision_changes {
            padding: 15px 0;
            display: flex;
            flex-wrap: wrap;
            font-family: $Commissioner-Medium;
            @extend .Commissioner-Medium15;
            color: $text-clr-33;

            .change_title {
              padding: 4px 10px;
              box-sizing: border-box;
              background-color: $bg-Platinum-gray;
              flex: 1 1 100%;
            }
          }

          .no_revision_changes {
            font-family: $Commissioner-Medium;
            @extend .Commissioner-Medium16;
            color: $text-clr-33;
            margin-top: 10px;
          }
        }
      }
    }
  }
}

// Restore Modal for Revision History
.restore_modal {
  #revert-modal-title {
    font-family: $Commissioner-Medium;
    @extend .Commissioner-Medium21;
    color: $heading-Dune-black-33
  }

  .modal-description {
    font-family: $Commissioner-Regular;
    @extend .Commissioner-Regular16;
    color: $text-22-clr;
    margin-top: 10px;

    &.top {
      font-family: $Commissioner-Medium;
      @extend .Commissioner-Medium16;
      color: $text-clr-BC;
    }
  }

  .btn_restore {
    margin: 25px 0 15px 0;
    display: flex;
    justify-content: flex-end;

    button {
      padding: 13px 20px;
      min-height: unset;
      line-height: 1;
      border-radius: 4px;
      text-transform: capitalize;
      font-family: $Commissioner-Medium;
      @extend .Commissioner-Medium15;
      white-space: nowrap;
      box-shadow: none;

      &:hover {
        box-shadow: none;
      }
    }

    .MuiButton-outlinedPrimary,
    .MuiButton-outlinedPrimary:hover {
      margin-right: 20px;
      background-color: $bg-white;
    }
  }

  .close-icon {
    position: absolute;
    top: 15px;
    right: 20px;
    cursor: pointer;
  }
}
