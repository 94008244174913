@import '../base/fonts';
@import '../base/global';
@import '../abstracts/colors';
@import '../abstracts/mixins';

.home_intelligence-wrapper {
  max-width: 1366px;
  margin: auto;

  @media screen and (min-width: 1400px) {
    max-width: 1440px;
  }

  @media screen and (min-width: 1500px) {
    max-width: 1500px;
  }

  .home_intelligence_inner_wrapper {
    padding: 50px 0px;
    background-image: url(../../../../../images/img-hero-home.svg);
    background-position: left 459px top 96px;
    background-repeat: no-repeat;

    @media screen and (min-width: 1400px) {
      background-position: left 496px top 94px;
    }

    @media screen and (min-width: 1475px) {
      background-position: left 527px top 94px;
    }

    @include respond-to('large') {
      background-position: left 417px top 96px;
    }

    @include respond-to('medium') {
      background-position: center;
    }

    .home-wrapper {
      width: 83%;
      margin: auto;
      max-width: 1066px;
    }

    @include respond-to('exx-small') {
      width: 96%;
    }

    .row {
      justify-content: flex-start;
    }

    .col-4 {
      flex: 0 0 30%;
      width: 30%;
      margin-right: 0px;
      margin-left: 1.9%;
      margin-bottom: 20px;

      @include respond-to('small') {
        margin-bottom: 20px;
        box-sizing: border-box;

        &:nth-child(3n) {
          @include respond-to('small') {
            margin-right: 0px;
          }
        }
      }

      @include respond-to('medium') {
        margin-right: 1%;
      }
    }

    a {
      background-color: $bg-white;
      width: 100%;
      box-sizing: border-box;
    }

    .vew-section-wrapper {
      padding: 20px 20px 40px 20px;
      background-color: $bg-white;
      width: 100%;
      box-sizing: border-box;

      @include respond-to('small') {
        text-align: center;
      }

      @include respond-to('exx-small') {
        padding: 20px 16px;
        min-width: 55px;
      }
    }

    .intelligence_heading {
      @extend .Karbon-Semibold51;
      font-family: $Karbon-Semibold;
      color: $text-clr-33;
      padding-left: 15px;

      @include respond-to('small') {
        text-align: center;
      }
    }

    .intelligence_subheading {
      @extend .Karbon-Regular22;
      color: $text-clr-33;
      font-family: $Karbon-Regular;
      padding: 20px 0 40px 15px;

      @include respond-to('small') {
        text-align: center;
      }
    }

    .search-wrapper {
      height: 60px;
      background-color: $bg-white;
      display: inline-flex;
      padding: 4px;
      border-radius: 5px;
      width: 518px;
      margin-left: 15px;
      box-sizing: border-box;
      justify-content: space-between;

      @include respond-to('small') {
        width: 87%;
        margin-left: 5%;
      }

      @include respond-to('exx-small') {
        margin-left: 20px;
      }

      .input-box {
        width: 71%;
        @extend .Commissioner-Regular16;
        color: $text-clr-33;
        font-family: $Commissioner-Regular;
        padding-left: 21px;
      }

      .search-btn {
        background-color: $text-clr-BC;
        color: $text-clr-white;
        margin: 3.5px 0px 3.5px 0px;
        @extend .Commissioner-Medium16;
        border: none;
        width: 148px;
        border-radius: 5px;
        font-family: $Commissioner-Regular;
        cursor: pointer;
        display: flex;
        align-items: center;
        padding-left: 20px;

        .MuiSvgIcon-root {
          font-size: 20px;
          padding-right: 15px;
          position: relative;
          top: 2px;
        }
      }
    }

    .view_data_wrapper {
      .view_data_heading {
        font-family: $Karbon-Semibold;
        @extend .Karbon-samibold29;
        color: $text-clr-33;
        padding: 80px 0px 40px 15px;

        @include respond-to('small') {
          display: none;
        }
      }

      a {
        text-decoration: none;
        color: inherit;

        &:hover {
          text-decoration: none;
        }
      }

      .left_section {
        @include respond-to('small') {
          width: 92%;
        }

        .row {
          @include respond-to('small') {
            width: 100%;
          }
        }
      }

      .bg_view_data {
        width: 65px;
        height: 62px;
        border-radius: 13px;
        margin: 20px 0px 30px 0px;

        @include respond-to('small') {
          margin: 0 auto 10px auto;
          width: 37px;
          height: 37px;
        }
      }

      .data_heading {
        @extend .Karbon-Regular25;
        font-family: $Karbon-Semibold;
        color: $heading-blk-3B;
        display: inline-block;

        @include respond-to('small') {
          text-align: center;
        }

        @include respond-to('exx-small') {
          font-size: 1.262rem;
        }
      }

      .data_text {
        @extend .Commissioner-Medium16;
        color: $text-clr-mistblue-77;
        padding: 20px 0px 30px 0px;
        font-family: $Commissioner-Regular;

        @include respond-to('small') {
          display: none;
        }

        > *:first-child {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
        }

        > *:nth-child(n + 2) {
          display: none;
        }
      }

      .data_count-wrapper {
        display: flex;
        color: $text-clr-boulder-77;
        font-family: $Karbon-Medium;
        @extend .Karbon-Medium17;
        position: absolute;
        bottom: 40px;

        .data_count {
          margin-right: 30px;
          display: flex;
          align-items: center;
          font-family: $Commissioner-Medium;
          font-size: 0.875rem;

          img {
            margin-right: 8px;
            width: 14px;
          }
        }

        .data_statics {
          display: flex;
          align-items: center;
          font-family: $Commissioner-Medium;
          font-size: 0.875rem;

          img {
            margin-right: 8px;
            width: 14px;
          }
        }

        @include respond-to('small') {
          display: none;
        }
      }

      @include respond-to('small') {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        margin-top: 15px;
      }

      &.global_announcement_view {
        display: flex;

        @include respond-to('small') {
          flex-direction: column;
          align-items: center;
        }

        .left_section {
          width: 70%;

          @include respond-to('small') {
            width: 92%;
          }

          .row {
            @include respond-to('small') {
              width: 100%;
            }

            .col-4 {
              flex: 0 0 47%;
              width: 47%;

              @include respond-to('small') {
                flex: 0 0 30%;
                width: 30%;
              }
            }
          }
        }

        .right_section {
          width: 30%;
          align-self: flex-start;

          @include respond-to('small') {
            align-self: auto;
            width: 88%;
          }
        }
      }
    }
  }
}

.home_alert_warning {
  background-color: $bg-error !important;
  justify-content: center;

  .MuiAlert-icon {
    display: none;
  }

  .MuiAlert-message {
    font-family: $Commissioner-Medium;
    @extend .Commissioner-Medium16;
    color: $text-22-clr;
    padding: 0 12px;
    width: 100%;

    a {
      color: $text-22-clr;
    }

    .home_banner {
      display: flex;
      justify-content: center;
      align-items: center;

      .announcements_list {
        display: flex;

        span {
          display: inline-block;
          max-width: 900px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          margin-right: 7px;

          @include respond-to('large') {
            max-width: 580px;
          }

          @include respond-to('medium') {
            max-width: 430px;
          }

          @include respond-to('small') {
            max-width: 260px;
          }

          @include respond-to('ex-small') {
            max-width: 150px;
          }

          @include respond-to('exx-small') {
            max-width: 80px;
          }
        }
      }

      .Pagination {
        display: flex;
        align-items: center;
        position: absolute;
        right: 0;

        ul {
          li {

            button {
              @include respond-to('small') {
                padding: 0;
                margin: 0;
              }
            }
            svg {
              color: $text-22-clr;
              font-size: 30px;
            }
          }

          li:not(:first-child, :last-child) {
            display: none;
          }
        }
      }
    }
  }
}
