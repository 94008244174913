@import '../base/fonts';
@import '../base/global';
@import '../abstracts/colors';
@import '../abstracts/mixins';

.generic-modal-wrapper {
  background: $text-clr-white;
  padding: 30px;
  box-sizing: border-box;
  border-radius: 5px;
  outline: none;

  &.remove-modal {
    position: absolute;
    top: 180px;
    left: 50%;
    width: 500px;
    transform: translate(-50%, -50%);

    @include respond-to('ex-small') {
      width: 90%;
    }
  }

  .close_popup {
    font-size: 15px;
    position: absolute;
    right: 14px;
    top: 13px;
    color: $text-clr70;
    cursor: pointer;
    z-index: 99;
  }

  .header {
    display: flex;
  }

  .generic-modal-title {
    margin: 0 0;
    color: $bg-Dune-back;
    @extend .Commissioner-Medium21;
    font-family: $Commissioner-Medium;
    font-weight: 500;
  }

  .text-description {
    padding: 20px 0px 30px 0px;
    margin: 0 0;
    @extend .Commissioner-Medium15;
    font-family: $Commissioner-Regular;
    color: $text-clrE20;

    @include respond-to('small') {
      padding: 5px 0px 15px 0px;
    }
  }

  .selected_tags_wrapper {
    display: flex;
    gap: 20px 10px;
    flex-wrap: wrap;
    padding-bottom: 30px;

    @include respond-to('small') {
      gap: 15px 10px;
    }

    span {
      line-height: 1;
      border-radius: 9px;
      padding: 7px 10px 9px 10px;
      @extend .Commissioner-Medium14;
      font-family: $Commissioner-Medium;
      display: inline-block;
      text-transform: capitalize;
      cursor: pointer;
    }

    .default {
      color: $text-clr-33;
      background-color: $bg-Link-Water;
    }

    .selected {
      background-color: $text-clr-BC;
      color: $text-clr-white;
    }
  }

  .btn_wrapper {
    display: flex;
    justify-content: flex-end;

    button {
      padding: 13px 20px;
      line-height: 1;
      font-family: $Karbon-Medium;
      text-transform: capitalize;
      @extend .Karbon-Medium18;
      white-space: nowrap;
    }

    .MuiButton-outlinedPrimary {
      margin-right: 20px;
      background-color: $text-clr-white;
    }

    .MuiButton-containedPrimary {
      background-color: $text-clr-BC;
    }
  }
}
