@import "../../Assets/sass/base/fonts";
@import "../../Assets/sass/base/global";
@import "../../Assets/sass/abstracts/colors";
@import "../../Assets/sass/abstracts/mixins";

.managegroup_page_wrapper {
  .managegroup_page_wrapper_section {
    @extend .max_width;
    padding: 20px 0px;
    width: 94%;
    max-width: 1366px;

    .managegroup_access_wrapper {
      background-color: $bg-white;
      border-radius: 5px;
      padding: 30px 30px 40px;
      text-align: center;

      .message {
        margin-bottom: 20px;
        font-family: $Commissioner-Regular;
        @extend .Commissioner-Regular16;
        color: $text-clr-33;
      }

      .message_link {
        font-family: $Commissioner-Medium;
        @extend .Commissioner-Medium16;
        color: $text-clr-BC;
        text-decoration: none;
      }
    }
  }
}

.managegroup_left_section {
  &.col-3 {
    display: block;
    flex: 0 0 21%;
    margin-left: 0;
    margin-right: 20px;
    width: 21%;

    @include respond-to("medium") {
      width: 100%;
    }
  }

  .managegroup_menu_wrapper {
    background-color: $bg-white;
    border-radius: 4px;

    .managegroup_menu {
      padding: 26px 25px 30px;

      .MuiListItem-root {
        padding: 8px 15px 10px;
        border-radius: 3px;

        a {
          color: $text-clr-mistblue-77;
          text-decoration: none;
        }

        &.active-menu {
          background-color: $bg-White-SmokeF7;

          span {
            a {
              font-family: $Commissioner-Medium;
              @extend .Commissioner-Medium15;
              color: $text-clr-33;
              font-weight: 600;
            }
          }
        }

        .MuiListItemText-root {
          margin: 0;

          .MuiListItemText-primary {
            font-family: $Commissioner-Medium;
            @extend .Commissioner-Medium15;
            color: $text-clr-mistblue-77;
          }
        }

        &.active {
          background-color: $bg-White-SmokeF7;

          .MuiListItemText-primary {
            color: $text-clr-33;
            font-weight: 600;
          }
        }
      }

      @include respond-to("small") {
        padding: 16px 15px 20px;
      }
    }
  }
}

.managegroup_right_section {
  width: 78%;
  float: right;
  margin-bottom: 10px;

  @include respond-to("medium") {
    width: 100%;
    clear: both;
  }

  .move-group-bc {
    span {
      &.clickable {
        cursor: pointer;

        &:hover {
          color: $text-clr-BC;
        }
      }

      .MuiSvgIcon-root {
        top: 7px;
        right: 3px;
        position: relative;

        @include respond-to('small') {
          top: 5px;
        }
      }
    }
  }
}

.managegroup_section_wrapper {
  padding: 30px;
  background-color: $text-clr-white;
  border-radius: 4px;

  .dialog-modal>div {
    padding: 0;
  }

  .managegroup_section_heading {
    font-family: $Commissioner-Medium;
    @extend .Commissioner-Medium21;
    color: $text-clr-33;

    span {
      font-family: $Commissioner-SemiBold;
      @extend .Commissioner-SemiBold;
      font-size: 1.312rem;
      font-weight: 600;
    }
  }

  .managegroup_section_sub {
    p {
      font-family: $Commissioner-Regular;
      @extend .Commissioner-Medium14;
      color: $text-clr-33;
      margin-bottom: 5px;

      span {
        font-family: $Commissioner-SemiBold;
        @extend .Commissioner-SemiBold;
      }
    }
  }

  .group_list_wrapper {
    margin: 30px 0 0;
    background-color: $bg-clr-FC;
    border: 1px solid $bg-clr-E7;
    height: 300px;
    overflow-x: hidden;
    overflow-y: auto;

    .MuiList-root {
      padding: 20px 20px 5px 20px;

      .move-group-list {
        width: 100%;
      }

      .MuiListItemIcon-root {
        color: $text-clr-white;
        background-color: $text-clr-BC;
        border-radius: 2px;
        margin-right: 10px;
        min-width: auto;
      }

      .MuiListItem-container {
        margin-bottom: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid $bg-clr-E7;

        &:last-child {
          border-bottom: none;
          margin-bottom: 0px;
        }

        .expandable {
          cursor: pointer;

          span:hover {
            color: $text-clr-BC;
          }
        }

        .non-expandable {
          color: $text-clr70;
          background-color: $bg-clr-4E5;
        }

        .MuiListItemText-root {
          margin: 0;

          .MuiListItemText-primary {
            font-family: $Commissioner-Regular;
            @extend .Commissioner-Regular17;
            color: $text-clr-33;
          }
        }

        .MuiRadio-root {
          margin: 0 0 10px 0;
          padding: 5px;
        }
      }
    }

    &::-webkit-scrollbar {
      background-color: $bg-White-SmokeF7;
      width: 15px;
    }

    &::-webkit-scrollbar-track {
      background-color: $bg-White-SmokeF7;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 20px;
      border: 4px solid $bg-White-SmokeF7;
      background-color: $bg-clr-d6;
    }
  }

  .btn-wrapper-movegroup {
    display: flex;
    margin-top: 30px;
    justify-content: end;

    @include respond-to('small') {
      margin-top: 30px;
    }

    button {
      padding: 13px 20px;
      min-height: unset;
      line-height: 1;
      font-family: $Karbon-Medium;
      border: 1px solid $text-clr-BC;
      border-radius: 4px;
      text-transform: capitalize;
      @extend .Karbon-Medium18;
      white-space: nowrap;
      box-shadow: none;

      &:hover {
        box-shadow: none;
      }

      @include respond-to('small') {
        padding: 9px 16px;
        width: unset;
      }
    }

    .MuiButton-outlinedPrimary,
    .MuiButton-outlinedPrimary:hover {
      color: $text-clr-BC;
      background-color: $text-clr-white;
    }

    .MuiButton-contained.Mui-disabled {
      border-color: transparent;
    }
  }
}

.businessTeamMenu {
  .MuiPopover-paper {
    box-shadow: none;
    border: 1px solid $bf-gray-Seashell;
    border-radius: 5px !important;

    .MuiMenu-list {
      .MuiMenuItem-root {
        padding: 8px 20px;
        font-family: $Commissioner-Medium;
        @extend .Commissioner-Medium14;
        color: $text-clr-33;

        @include respond-to('ex-small') {
          min-height: 26px;
        }

        &:last-child {
          color: $text-22-clr;
        }

        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}
