@import '../../../Assets/sass/base/fonts';
@import '../../../Assets/sass/base/global';
@import '../../../Assets/sass/abstracts/colors';
@import '../../../Assets/sass/abstracts/mixins';

.set-group-view-wrapper {
  background-color: $bg-white;

  @include respond-to('large') {
    top: 20px;
    transform: translate(0%, 0%);
  }

  .setgroup-content_wrapper {
    @extend .Commissioner-Medium21;
    color: $text-clr-33;
    font-family: $Commissioner-Medium;
    display: block;

    .content_heading {
      font-family: $Commissioner-SemiBold;
      @extend .Commissioner-SemiBold;
      font-size: 20px;
    }

    .content_subheading {
      font-family: $Commissioner-Regular;
      @extend .Commissioner-Regular14;
      color: $text-clr-33;
      padding-top: 20px;
      padding-bottom: 25px;
    }
  }

  .group_order_wrapper {
    min-height: 240px;

    .group_items_wrapper {
      width: 100%;

      &.error {
        padding-bottom: 20px;
      }

      >div {
        margin: 10px 0;

        .group_items {
          display: flex;
          justify-content: space-between;
          border: 1px solid $bdr-clr-c1c1;
          font-family: $Commissioner-Regular;
          @extend .Commissioner-Regular15;
          padding: 12px 20px 15px 18px;
          border-radius: 3px;
        }
      }
    }
  }

  // Button css
  .btn-wrapper-setgroup {
    display: flex;
    margin-top: 30px;
    justify-content: flex-end;

    @include respond-to('small') {
      margin-top: 15px;
    }

    button {
      padding: 13px 20px;
      min-height: unset;
      line-height: 1;
      color: $text-clr-BC;
      font-family: $Commissioner-Medium;
      border: 1px solid $text-clr-BC;
      border-radius: 4px;
      text-transform: capitalize;
      @extend .Commissioner-Medium15;
      white-space: nowrap;
      box-shadow: none;

      &:hover {
        box-shadow: none;
      }
    }

    .MuiButton-outlinedPrimary,
    .MuiButton-outlinedPrimary:hover {
      margin-right: 20px;
      color: $text-clr-BC;
      background-color: $text-clr-white;
    }

    .MuiButton-containedPrimary,
    .MuiButton-containedPrimary:hover {
      background-color: $text-clr-BC;
      color: $text-clr-white;
    }

    .MuiButton-outlined.Mui-disabled {
      color: $text-clr-99;
      border: 1px solid $text-clr-99;
    }

    .MuiButton-contained.Mui-disabled {
      border-color: $text-clr-99;
      color: $text-clr-white;
      box-shadow: none;
      background-color: $text-clr-99;
    }
  }
}
