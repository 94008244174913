@import '../Assets/sass/base/fonts';
@import '../Assets/sass/base/global';
@import '../Assets/sass/abstracts/colors';
@import '../Assets/sass/abstracts/mixins';

.application_details_wrapper {
  .details_heading {
    color: $bg-gray-Boulder;
    @extend .Commissioner-Medium14;
    font-family: $Commissioner-SemiBold;
    padding-bottom: 20px;
  }

  ul,
  ol {
    padding: 0 0;
    margin: 0 0;
  }

  .full_screen_wrapper {
    background-color: $text-clr-white;
    padding: 20px;
    margin-bottom: 20px;
    position: relative;
    border-radius: 5px;

    .fullscreen {
      border: 1px solid $bdr-clr-E8;
      line-height: 0px;
      margin-bottom: 20px;

      .loader {
        min-height: inherit
      }
    }

    button {
      font-family: $Commissioner-SemiBold;
      @extend .Commissioner-Regular15;
      color: $text-clr-BC;
      display: inline-flex;
      align-items: center;
      margin: 0 0;
      cursor: pointer;
      width: auto;
      background-color: $text-clr-white;
      box-shadow: none;
      justify-content: flex-end;
      text-transform: capitalize;
      float: right;
      padding: 0 8px;

      img {
        margin-right: 6px
      }
    }
  }

  .section_wrapper {
    background: $bg-white;
    border-radius: 5px;
    padding: 25px 20px 20px 20px;
    margin-bottom: 20px;

    .section_heading,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      @extend .Commissioner-Regular15;
      color: $bg-Dune-back;
      padding: 0px 0px 15px 0px;
      font-family: $Commissioner-Medium;
      margin: 0 0;
      padding-top: 25px;

      &:first-child {
        padding-top: 0px;
      }
    }

    .pd_top {
      padding-top: 20px;
    }

    .section_details,
    p,
    div,
    span,
    li {
      @extend .Commissioner-Regular15;
      color: $bg-Dune-back;
      font-family: $Commissioner-Regular;
      margin: 0 0;
      padding-bottom: 10px;

      a {
        color: $text-clr-BC;
        text-decoration: underline !important;
      }
    }

    ul,
    ol {
      padding-left: 5px;
      list-style-position: inside;

      li {
        padding: 7px 10px 7px 10px;
        display: list-item;
        list-style-type: decimal;

        &:nth-child(odd) {
          background-color: $bg-clr-FA;
        }

        span {
          padding-left: 6px;
        }
      }
    }

    ul {
      list-style-type: decimal;

      li {
        display: list-item;
        list-style-type: none;
      }
    }
  }

  .section_heading.upper_case {
    padding: 20px 0px;
    text-transform: uppercase;
    font-family: $Commissioner-SemiBold;
    color: $text-clr-boulder-77;
  }
}

.view_application_wrapper {
  .alert_info {
    justify-content: center;

    .MuiAlert-icon {
      margin-right: 8px;
      padding: 0;
      img {
        width: 20px;
      }
    }

    .MuiAlert-message {
      font-family: $Commissioner-SemiBold;
      @extend .Commissioner-Regular15;
      color: $text-clr-BC;
      padding: 3px 0;
    }
  }

  .group_heading_wrapper {
    padding: 0 40px;
    margin-top: 25px;

    .group_heading {
      font-family: $Commissioner-Medium;
      @extend .Commissioner-Medium16;
    }

    .group_subheading {
      padding: 20px 0 0;
      p {
        font-family: $Commissioner-Medium;
        @extend .Commissioner-Medium15;
        margin: 0;
        a {
          color: $text-clr-BC;
        }
      }
    }
  }

  .full_screen_wrapper {
    padding: 40px 40px 60px;
    position: relative;

    .full_screen {
      padding: 20px;
      background-color: $bg-white;
      
      .body-embed-url {
        text-align: end;

        .fullscreen {
          border: 1px solid $bdr-clr-E8;
          line-height: 0px;
          margin-bottom: 20px;
    
          .loader {
            min-height: inherit
          }
        }
  
        button {
          font-family: $Commissioner-SemiBold;
          @extend .Commissioner-Regular17;
          color: $text-clr-BC;
          display: inline-flex;
          align-items: center;
          margin: 0 0;
          cursor: pointer;
          width: auto;
          background-color: $text-clr-white;
          box-shadow: none;
          justify-content: flex-end;
          text-transform: capitalize;
          padding: 0 8px;
        }
      }
    }
  }
}
