@import '../base/fonts';
@import '../base/global';
@import '../abstracts/colors';
@import '../abstracts/mixins';

.home_application_wrapper {
  padding: 50px 0px;
  background-color: $bg-White-SmokeF7;
  display: none;

  .container {
    width: 73%;
    max-width: 1015px;
  }

  .h-hwrapper {
    padding: 20px 0px 40px 0px;
    display: flex;
    align-items: flex-start;

    @include respond-to('medium') {
      justify-content: center;
    }

    .application_explore {
      padding-bottom: 0px;

      @include respond-to('medium') {
        justify-content: center;
      }
    }
  }

  .application-card-section {
    background-color: $text-clr-white;
    width: 100%;
    position: relative;

    a {
      text-decoration: none;
    }

    .app-section-bottom {
      background-color: $bg-white;
      padding: 21px 20px 31px 20px;
    }

    .image {
      height: 151px;
      background-color: $bg-clr-E1;
      position: relative;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .name {
      font-size: 17px;
      line-height: 23px;
      font-family: $Karbon-Medium;
      color: $text-clr-33;
      @extend .flex-center;
      justify-content: space-between;

      .MuiSvgIcon-root {
        color: $bg-green400;
      }
    }

    .owner {
      @extend .Commissioner-Regular14;
      padding: 8px 0px 20px 0px;
      color: $text-clr-boulder-77;
      display: flex;
      align-items: center;

      .MuiSvgIcon-root {
        padding-right: 5px;
        color: $text-clr-boulder-77;
        font-size: 1.25rem;
      }
    }

    .details {
      @extend .Commissioner-Regular14;
      color: $text-clr-boulder-77;
      font-family: $Commissioner-Regular;
      padding-bottom: 61px;

      >*:first-child {
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
        margin: 0px
      }

      >*:nth-child(n+2) {
        display: none;
      }

      * {
        margin: 0;
      }
    }

    .count {
      padding-top: 10px;
      justify-content: space-between;
      @extend .flex-center;
      position: absolute;
      bottom: 31px;
      left: 20px;
      right: 20px;

      .left-icon {
        color: $text-clr-boulder-77;
        font-family: $Karbon-Medium;
        @extend .Karbon-Medium17;

        img {
          width: 14px;
          height: 10px;
          margin-right: 7px;
        }
      }

      .count-right {
        @extend .flex-center;
      }

      .gxp-label {
        color: $text-clr-33;
        background-color: $bg-clr-E7;
        padding: 2px 8px 4px 8px;
        border-radius: 9px;
        font-family: $Commissioner-SemiBold;
        margin-right: 20px;
        cursor: pointer;
        font-size: 12px;
      }

      .rating-icon {
        @extend .flex-center;
        cursor: pointer;

        .MuiSvgIcon-root {
          font-size: 1.25rem;
          color: $text-clrD6;
        }
      }
    }
  }

  .application-heading {
    @extend .karbon-heading-w29;
    color: $text-clr-33;
    font-family: $Karbon-Semibold;

    @include respond-to('small') {
      text-align: center;
      margin-bottom: 15px;
      justify-content: center;
    }
  }

  .explore-option {
    color: $text-clr-33;
    font-size: 16px;
    line-height: 19px;
    font-family: $Commissioner-Medium;
    @extend .flex-center;
    justify-content: flex-start;

    @include respond-to('small') {
      justify-content: center;
    }

    .active {
      background-color: $bg-Dark-blue;
      color: $text-clr-white;
      padding: 10px 15px;
    }

    li {
      padding: 0px 30px;
    }
  }
}
