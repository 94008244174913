img {
  width: auto;
  height: auto;
}
.content-home{
  display: flex;
  flex-direction: column;
  background: white;
  .header--image {
    width: 100%;
    height: 30vh;
    background-image: url('../../../images/our_groups_navcard.jpg');
    background-position: 50% 60%;
    background-repeat: no-repeat;
  }
  .content-company-name{
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin-left: 1rem;
  }
  .content-home-wrapper {
    display: flex;
    justify-content: space-between;
    width: 95%;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 2rem;
  }
  .content-home-wrapper.content-data {
    width: 100%;
  }
  .main-title, .process-title {
    color: #00417b;
    font-weight: 700;
    font-size: 2rem;
    line-height: 1.6;
    letter-spacing: .0075em;
  }
  .tab-bottom-style{
    padding-bottom: 20px;
  }
  .tab-hide {
    display: none;
  }
  .webform-share-iframe {
    width: 1px;
    min-width: 100%;
  }
}

@media(max-width: 600px) {
  .main-title, .process-title {
    font-size: 1.2rem
  }
}

.content-mailto-link {
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.mail-icon__shift-left {
  margin-left: 1rem;
}

.content-home-info__space-apart {
  & + .content-home-info__space-apart {
    margin-top: 1rem;
  }

  & > *:not(:first-child) {
    margin-left: 1rem;
  }
  & > .body-embed-url {
    margin-left: 0;
  }
  /* padding-left: 2em; */
}

.content-home-info--full-column {
  width: 100%;
}
.content-home-info--left-column {
  width: 80%;
}
.content-home-info--right-column {
  width: 20%;
  margin-left: 10px;
}

.alternative-names__remove-style-type {
  list-style-type: none;
}
